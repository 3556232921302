import React from 'react';
import { Popover, Paper, Button, Box, Typography } from '@mui/material';

const ConfirmationPopper = ({ open, anchorEl, onConfirm, onCancel, message }) => {
    return (
        <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={onCancel}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            sx={{ zIndex: 1302 }} // Ensures it's on top
        >
            <Paper sx={{ p: 2, border: '1px solid', borderColor: 'grey.300' }}>
                <Typography variant="body1" gutterBottom>
                    {message}
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button onClick={onCancel} sx={{ mr: 1 }}>Cancel</Button>
                    <Button onClick={onConfirm} variant="contained" color="error">Confirm</Button>
                </Box>
            </Paper>
        </Popover>
    );
};

export default ConfirmationPopper;
