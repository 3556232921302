// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from "firebase/firestore";
import { getDatabase } from 'firebase/database';


const firebaseConfig = {
    apiKey: "AIzaSyBxWrAKls8DlScC3lsl5aqTZO1xqq7lzm4",
    authDomain: "mail-ex-max.firebaseapp.com",
    projectId: "mail-ex-max",
    databaseURL: 'https://mail-ex-max-default-rtdb.asia-southeast1.firebasedatabase.app',
    storageBucket: "mail-ex-max.appspot.com",
    messagingSenderId: "21666223834",
    appId: "1:21666223834:web:55de43696731c129a4b7e3"
  };

  const app = initializeApp(firebaseConfig);
  const database = getDatabase(app);
  const db = getFirestore(app);
export const auth = getAuth(app);
export { db, database };
