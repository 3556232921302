import React, { useState, useEffect, useCallback } from 'react';
import {
  List, ListItem, ListItemText, IconButton, CircularProgress, Typography, Divider
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { db } from './firebase';
import { doc, getDoc, updateDoc, arrayRemove } from 'firebase/firestore';

const SharepointFolderList = ({ userId }) => {
  const [extractionFolders, setExtractionFolders] = useState([]);
  const [folderPairs, setFolderPairs] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchCurrentFolderPairs = useCallback(async () => {
    setLoading(true);
    const settingsRef = doc(db, 'settings', userId);
    try {
      const docSnap = await getDoc(settingsRef);
      if (docSnap.exists()) {
        const folderPairs = docSnap.data().folderPairs || [];
        setFolderPairs(folderPairs);
        const extractionFolders = folderPairs.map(pair => pair.extractionFolder);
        setExtractionFolders(extractionFolders);
      } else {
        console.log('No such document in Firestore.');
      }
    } catch (error) {
      console.error('Failed to fetch current folder pairs', error);
    } finally {
      setLoading(false);
    }
  }, [userId]);

  useEffect(() => {
    fetchCurrentFolderPairs();
  }, [userId, fetchCurrentFolderPairs]);

  const handleDeletePair = async (pair) => {
    try {
      const settingsRef = doc(db, 'settings', userId);
      await updateDoc(settingsRef, {
        folderPairs: arrayRemove(pair),
      });

      setFolderPairs(folderPairs.filter(p => p !== pair));
      setExtractionFolders(extractionFolders.filter(f => f.id !== pair.extractionFolder.id));
    } catch (error) {
      console.error('Failed to delete folder pair', error);
    }
  };

  return (
    <div>
      <Typography variant="h6" gutterBottom>SharePoint Folders List</Typography>
      {loading ? <CircularProgress /> : (
        <List>
          {folderPairs.map((pair, index) => (
            <React.Fragment key={index}>
              <ListItem secondaryAction={
                <IconButton edge="end" aria-label="delete" onClick={() => handleDeletePair(pair)}>
                  <DeleteIcon />
                </IconButton>
              }>
                <ListItemText 
                  primary={<Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>Mail Folder: {pair.extractionFolder.fullPath}</Typography>}
                  secondary={<Typography variant="body2" color="textSecondary">SharePoint Folder: {pair.extractionFolder.selectedSharePointFolder.path}</Typography>}
                />
              </ListItem>
              {index < folderPairs.length - 1 && <Divider />}
            </React.Fragment>
          ))}
        </List>
      )}
    </div>
  );
};

export default SharepointFolderList;
