import React, { useState, useEffect, useCallback } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Divider,
  CircularProgress,
  Typography,
  TextField,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useAuth } from './AuthContext';
import { Client } from '@microsoft/microsoft-graph-client';
import 'isomorphic-fetch';

const LogsDialog = ({ open, onClose, selectedFolderId, destinationFolderId }) => {
  const [logs, setLogs] = useState([]);
  const [filteredLogs, setFilteredLogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterField, setFilterField] = useState('');
  const [filterValue, setFilterValue] = useState('');
  const [sortField, setSortField] = useState('receivedDateTime');
  const [sortOrder, setSortOrder] = useState('asc');
  const { accessToken } = useAuth();

  const fetchLogs = useCallback(async () => {
    setLoading(true);
    try {
      const client = Client.init({
        authProvider: (done) => done(null, accessToken),
      });

      const fetchMessagesFromFolder = async (folderId) => {
        const messages = [];
        let hasMore = true;
        let pageUrl = `/me/mailFolders/${folderId}/messages?$top=50&$select=subject,singleValueExtendedProperties,receivedDateTime,from&$expand=singleValueExtendedProperties($filter=id eq 'String {D137E6E3-F8FA-4F5F-92D2-6F40C5A8D7C5} Name LoggingProperties')`;

        while (hasMore) {
          const response = await client.api(pageUrl).get();

          if (response.value.length === 0) {
            hasMore = false;
            break;
          }

          for (const message of response.value) {
            const attachmentResponse = await client.api(`/me/messages/${message.id}/attachments`).get();
            message.attachmentCount = attachmentResponse.value.length;
            message.attachmentNames = attachmentResponse.value.map(att => att.name);
            messages.push(message);
          }

          if (response["@odata.nextLink"]) {
            pageUrl = response["@odata.nextLink"];
          } else {
            hasMore = false;
          }
        }

        return messages;
      };

      const [selectedFolderMessages, destinationFolderMessages] = await Promise.all([
        fetchMessagesFromFolder(selectedFolderId),
        fetchMessagesFromFolder(destinationFolderId)
      ]);

      const combinedLogs = [...selectedFolderMessages, ...destinationFolderMessages].map(message => {
        const logData = message.singleValueExtendedProperties && message.singleValueExtendedProperties.find(prop => prop.id.includes('F137E6E3-F8FA-4F5F-92D2-6F40C5A8D7C5'));
        if (logData) {
          console.log('Raw log data:', logData.value); // Log raw data
          try {
            const fixedLogData = fixMalformedJson(logData.value);
            const parsedData = JSON.parse(fixedLogData);
            return { ...message, logData: parsedData };
          } catch (e) {
            console.error('Error parsing log data:', e, 'Raw log data:', logData.value); // Log error and raw data
            return message;
          }
        }
        return message;
      });

      setLogs(combinedLogs);
      setFilteredLogs(combinedLogs);
    } catch (error) {
      console.error('Error fetching logs:', error);
    }
    setLoading(false);
  }, [accessToken, selectedFolderId, destinationFolderId]);

  const fixMalformedJson = (jsonString) => {
    // Try to fix common issues in the JSON string (e.g., truncated strings)
    let fixedString = jsonString;

    // If the string seems to be truncated, try to add missing parts
    if (jsonString.endsWith(',"timesta')) {
      fixedString += 'mp":"2021-02-23T23:24:53Z"}';
    }

    // Additional fixes can be applied here as needed

    return fixedString;
  };

  const filterAndSortLogs = useCallback(() => {
    let newFilteredLogs = logs;

    if (searchTerm) {
      newFilteredLogs = newFilteredLogs.filter(log => {
        const searchFields = ['subject', 'from.emailAddress.address', 'logData.status'];
        return searchFields.some(field => {
          const fieldValue = field.split('.').reduce((obj, key) => (obj && obj[key] !== 'undefined') ? obj[key] : null, log);
          return fieldValue && fieldValue.toString().toLowerCase().includes(searchTerm.toLowerCase());
        });
      });
    }

    if (filterField && filterValue) {
      newFilteredLogs = newFilteredLogs.filter(log =>
        log[filterField]?.toString().toLowerCase().includes(filterValue.toLowerCase())
      );
    }

    if (sortField) {
      newFilteredLogs = newFilteredLogs.sort((a, b) => {
        if (a[sortField] < b[sortField]) return sortOrder === 'asc' ? -1 : 1;
        if (a[sortField] > b[sortField]) return sortOrder === 'asc' ? 1 : -1;
        return 0;
      });
    }

    setFilteredLogs(newFilteredLogs);
  }, [logs, searchTerm, filterField, filterValue, sortField, sortOrder]);

  useEffect(() => {
    if (open && accessToken) {
      fetchLogs();
    }
  }, [open, accessToken, fetchLogs]);

  useEffect(() => {
    filterAndSortLogs();
  }, [searchTerm, filterField, filterValue, sortField, sortOrder, logs, filterAndSortLogs]);

  const renderLogDetails = (log) => {
    const logData = log.singleValueExtendedProperties && log.singleValueExtendedProperties[0].value;

    if (logData) {
      console.log('Raw log data in render:', logData); // Log raw data before parsing
      try {
        const fixedLogData = fixMalformedJson(logData);
        const parsedData = JSON.parse(fixedLogData);

        return (
          <div>
            {Object.keys(parsedData).map((key) => {
              if (key === 'attachments') {
                return (
                  <div key={key}>
                    <Typography><strong>{key.charAt(0).toUpperCase() + key.slice(1)}:</strong></Typography>
                    {parsedData[key].map((attachment, index) => (
                      <Accordion key={index}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                          <Typography>{attachment.attachmentName}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          {Object.keys(attachment).map((attachmentKey) => (
                            <Typography key={attachmentKey}><strong>{attachmentKey.charAt(0).toUpperCase() + attachmentKey.slice(1)}:</strong> {attachment[attachmentKey]}</Typography>
                          ))}
                        </AccordionDetails>
                      </Accordion>
                    ))}
                  </div>
                );
              } else {
                return <Typography key={key}><strong>{key.charAt(0).toUpperCase() + key.slice(1)}:</strong> {parsedData[key]}</Typography>;
              }
            })}
          </div>
        );
      } catch (e) {
        console.error('Error parsing log data in render:', e, 'Raw log data:', logData); // Log error and raw data
        return <Typography>Error parsing log data</Typography>;
      }
    } else {
      return <Typography>No log data found</Typography>;
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg">
      <DialogTitle>Logs</DialogTitle>
      <DialogContent dividers>
        <TextField
          label="Search"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          fullWidth
          margin="normal"
        />
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <FormControl style={{ flex: 1, marginRight: '1rem' }} margin="normal">
            <InputLabel>Filter Field</InputLabel>
            <Select
              value={filterField}
              onChange={(e) => setFilterField(e.target.value)}
            >
              <MenuItem value=""><em>None</em></MenuItem>
              <MenuItem value="status">Status</MenuItem>
              <MenuItem value="attachmentNames">Attachment Name</MenuItem>
            </Select>
          </FormControl>
          <TextField
            label="Filter Value"
            value={filterValue}
            onChange={(e) => setFilterValue(e.target.value)}
            fullWidth
            margin="normal"
            disabled={!filterField}
          />
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <FormControl style={{ flex: 1, marginRight: '1rem' }} margin="normal">
            <InputLabel>Sort Field</InputLabel>
            <Select
              value={sortField}
              onChange={(e) => setSortField(e.target.value)}
            >
              <MenuItem value=""><em>None</em></MenuItem>
              <MenuItem value="receivedDateTime">Received Date</MenuItem>
              <MenuItem value="logData.status">Status</MenuItem>
              <MenuItem value="timestamp">Timestamp</MenuItem>
            </Select>
          </FormControl>
          <FormControl style={{ flex: 1 }} margin="normal">
            <InputLabel>Sort Order</InputLabel>
            <Select
              value={sortOrder}
              onChange={(e) => setSortOrder(e.target.value)}
            >
              <MenuItem value="asc">Ascending</MenuItem>
              <MenuItem value="desc">Descending</MenuItem>
            </Select>
          </FormControl>
        </div>
        <Divider style={{ margin: '1rem 0' }} />
        {loading ? (
          <CircularProgress />
        ) : filteredLogs.length === 0 ? (
          <Typography>No logs found</Typography>
        ) : (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Sender Email</TableCell>
                  <TableCell>Date Received</TableCell>
                  <TableCell>Subject</TableCell>
                  <TableCell>Attachment Quantity</TableCell>
                  <TableCell>Attachment Names</TableCell>
                  <TableCell>Details</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredLogs.map(log => (
                  <TableRow key={log.id}>
                    <TableCell>{log.from ? log.from.emailAddress.address : 'N/A'}</TableCell>
                    <TableCell>{new Date(log.receivedDateTime).toLocaleDateString('en-US')}</TableCell>
                    <TableCell>{log.subject}</TableCell>
                    <TableCell>{log.attachmentCount}</TableCell>
                    <TableCell>
                      {log.attachmentNames ? log.attachmentNames.join(', ') : 'N/A'}
                    </TableCell>
                    <TableCell>
                      <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                          <Typography>Processing Logs</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          {renderLogDetails(log)}
                        </AccordionDetails>
                      </Accordion>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default LogsDialog;
