import React, { useState, useEffect, useRef, useCallback, useLayoutEffect } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography, CircularProgress } from '@mui/material';
import axios from 'axios';
import './tailwind.css';

const DownloadRuleRecorder = ({ newRule, onRecordedSteps }) => {
  const [clickableElements, setClickableElements] = useState([]);
  const [screenshot, setScreenshot] = useState("");
  const [viewport, setViewport] = useState({ width: 1280, height: 1024 });
  const [dpr, setDpr] = useState(1);
  const [error, setError] = useState(null);
  const [downloadStarted, setDownloadStarted] = useState(false);
  const [actions, setActions] = useState([]);
  const [finalActionIndex, setFinalActionIndex] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedElement, setSelectedElement] = useState(null);  // Save full element
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [loading, setLoading] = useState(false);
  const imageRef = useRef();
  const [scaleFactor, setScaleFactor] = useState({
    scaleFactorX: 1,
    scaleFactorY: 1,
  });
  const [selectorType, setSelectorType] = useState("css");

  useEffect(() => {
    if (newRule.urls[0]) {
      fetchClickableElements(newRule.urls[0]);
    }
  }, [newRule.urls[0]]);

  const fetchClickableElements = async (url) => {
    setLoading(true);
    try {
      const response = await axios.get(`http://localhost:5000/scrape`, {
        params: { url },
      });
      const filteredElements = response.data.clickableElements.filter(
        (element) => element.text !== "Close" && element.text !== "No text"
      );
      setClickableElements(filteredElements);
      setScreenshot(`data:image/png;base64,${response.data.screenshot}`);
      setViewport(response.data.viewport);
      setDpr(response.data.dpr);
    } catch (error) {
      setError(error.toString());
    }
    setLoading(false);
  };

  // Save the selected element (full object) when clicked
  const handleElementClick = (element, index) => {
    setSelectedElement(element);  // Save full element object (including cssSelector and xpath)
    setSelectedIndex(index);
    setDialogOpen(true);
  };

  // Handle dialog close and process based on the selected selector type (CSS or XPath)
  const handleDialogClose = (isFinalAction) => {
    let finalSelector;

    // Fetch the appropriate selector based on user choice (CSS or XPath)
    if (selectorType === 'xpath' && selectedElement) {
      finalSelector = selectedElement.xpath;
    } else if (selectorType === 'css' && selectedElement) {
      finalSelector = selectedElement.cssSelector;
    }

    if (isFinalAction) {
      setFinalActionIndex(selectedIndex);
    }

    processAction(finalSelector, selectedIndex, isFinalAction, selectorType);
    setDialogOpen(false);
  };

  const processAction = async (selector, index, isFinalAction, selectorType) => {
    setLoading(true);
    try {
      const response = await axios.post(`http://localhost:5000/perform-action`, {
        url: newRule.urls[0],
        actions: [...actions, { type: "click", selector, selectorType }],
        finalActionIndex: isFinalAction ? index : finalActionIndex,
      });

      setDownloadStarted(response.data.downloadStarted);
      setActions([...actions, { type: "click", selector, selectorType }]);

      if (isFinalAction || response.data.downloadStarted) {
        onRecordedSteps([...actions, { type: "click", selector, selectorType }]);
      } else {
        setClickableElements(
          response.data.newClickableElements.filter(
            (element) => element.text !== "Close" && element.text !== "No text"
          )
        );
        setScreenshot(`data:image/png;base64,${response.data.screenshot}`);
      }
    } catch (error) {
      setError(error.toString());
    }
    setLoading(false);
  };

  const calculateScaleFactor = useCallback(() => {
    if (imageRef.current) {
      const scaleFactorX = imageRef.current.clientWidth / (viewport.width * dpr);
      const scaleFactorY = imageRef.current.clientHeight / (viewport.height * dpr);
      setScaleFactor({ scaleFactorX, scaleFactorY });
    }
  }, [viewport.width, viewport.height, dpr]);

  useLayoutEffect(() => {
    calculateScaleFactor();
  }, [screenshot, calculateScaleFactor]);

  const renderElement = (element, index) => {
    const { scaleFactorX, scaleFactorY } = scaleFactor;

    if (!element || (!element.cssSelector && !element.xpath)) {
      console.error(`Element or selector missing for index ${index}:`, element);
      return null; 
    }

    const style = {
      position: "absolute",
      top: `${element.rect?.top || 0}px`,
      left: `${element.rect?.left || 0}px`,
      width: `${element.rect?.width || 100}px`,
      height: `${element.rect?.height || 30}px`,
      border: "2px solid red",
      boxSizing: "border-box",
      background: "rgba(255, 0, 0, 0.2)",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      color: "rgba(0, 0, 0, 0.5)",
    };

    return (
      <div key={index} style={style} onClick={() => handleElementClick(element, index)}>
        {element.cssSelector?.startsWith("a") && (
          <span style={{ opacity: 0.5 }}>{element.text || "Link"}</span>
        )}
        {element.cssSelector?.startsWith("button") && (
          <button style={{ pointerEvents: "none", opacity: 0.5 }}>
            {element.text || "Button"}
          </button>
        )}
        {!element.cssSelector?.startsWith("a") &&
          !element.cssSelector?.startsWith("button") && (
            <Typography
              variant="caption"
              style={{ background: "white", opacity: 0.5 }}
            >
              {element.text}
            </Typography>
          )}
      </div>
    );
  };

  return (
    <div className="card p-4" style={{ width: "1300px", height: "1100px" }}>
      <Typography variant="h6" className="typography">
        Download from Webpage Rule Setup
      </Typography>
      <Typography variant="body" className="typography">
        Click on the items in red to demonstrate how the file should be downloaded.
      </Typography>
      {error && <Typography color="error">{error}</Typography>}
      {loading && <CircularProgress />}
      {!loading && (
        <DialogContent style={{ position: "relative", padding: 0 }}>
          <div
            style={{
              position: "relative",
              width: "1280px",
              overflow: "hidden",
              border: "2px solid green",
            }}
          >
            <img
              ref={imageRef}
              src={screenshot}
              alt="Screenshot of the page"
              style={{ width: "100%", height: "100%" }}
            />
            <div
              style={{
                position: "absolute",
                border: "2px dashed blue",
                width: `${viewport.width}px`,
                height: `${viewport.height}px`,
                top: 0,
                left: 0,
              }}
            />
            {clickableElements.map((element, index) => (
              <div key={index}>
                {renderElement(element, index)}
                <div
                  style={{
                    position: "absolute",
                    top: `${element.rect.top}px`,
                    left: `${element.rect.left}px`,
                    width: `${element.rect.width}px`,
                    height: `${element.rect.height}px`,
                    border: "2px solid yellow",
                    pointerEvents: "none",
                  }}
                />
              </div>
            ))}
          </div>
        </DialogContent>
      )}
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>
          Will this action result in the file being downloaded?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>Is this the final action?</DialogContentText>
          <Typography variant="body1">Choose Selector Type:</Typography>
          <div>
            <label>
              <input
                type="radio"
                value="css"
                checked={selectorType === "css"}
                onChange={() => setSelectorType("css")}
              />
              CSS Selector
            </label>
            <label>
              <input
                type="radio"
                value="xpath"
                checked={selectorType === "xpath"}
                onChange={() => setSelectorType("xpath")}
              />
              XPath
            </label>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleDialogClose(false)} color="primary">
            No, there are more steps
          </Button>
          <Button onClick={() => handleDialogClose(true)} color="primary">
            Yes, file will download when this action is performed
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DownloadRuleRecorder;
