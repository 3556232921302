import React, { useState } from 'react';
import { Box, Button, Typography, TextField, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

const URLConstructor = ({ downloadUrlTemplate, onConstructURL }) => {
    const [constructedParts, setConstructedParts] = useState([]); // Store parts of the constructed URL
    const [customText, setCustomText] = useState(''); // State to hold custom text

    // Split the downloadUrlTemplate into static and variable parts
    const urlParts = downloadUrlTemplate.split(/(\{variable[0-9]+\})/); // Split by variables like {variable1}

    // Handle clicking a part to add it to the constructed URL
    const handleClickPart = (part) => {
        setConstructedParts((prev) => [...prev, part]);
    };

    // Handle adding custom text
    const handleAddCustomText = () => {
        if (customText.trim()) {
            setConstructedParts((prev) => [...prev, customText.trim()]);
            setCustomText(''); // Clear the input after adding
        }
    };

    // Handle removing a part from the constructed URL
    const handleRemovePart = (index) => {
        setConstructedParts((prev) => prev.filter((_, i) => i !== index));
    };

    // Handle constructing the final URL
    const handleConstructURL = () => {
        const finalURL = constructedParts.join(''); // Join parts together to form the URL
        onConstructURL(finalURL); // Send the final URL back to the parent
    };

    return (
        <Box mt={2}>
            <Typography variant="h6">Download URL Constructor</Typography>
            <Typography variant="body1">Click on the parts or add custom text to construct the download URL:</Typography>

            {/* Display parts from the downloadUrlTemplate */}
            <Box mt={1} display="flex" flexWrap="wrap">
                {urlParts.map((part, index) => (
                    <Button
                        key={index}
                        variant="outlined"
                        onClick={() => handleClickPart(part)}
                        style={{ margin: '5px' }}
                    >
                        {part}
                    </Button>
                ))}
            </Box>

            {/* Input for adding custom text */}
            <Box mt={2}>
                <TextField
                    label="Add Custom Text"
                    value={customText}
                    onChange={(e) => setCustomText(e.target.value)}
                    variant="outlined"
                    fullWidth
                />
                <Button
                    onClick={handleAddCustomText}
                    variant="contained"
                    color="primary"
                    className="button"
                    style={{ marginTop: '10px' }}
                >
                    Add Custom Text
                </Button>
            </Box>

            {/* Display constructed parts in an editable way */}
            <Box mt={2}>
                <Typography variant="body1">Constructed URL:</Typography>
                <Box mt={1} display="flex" flexWrap="wrap">
                    {constructedParts.map((part, index) => (
                        <Box
                            key={index}
                            display="flex"
                            alignItems="center"
                            border="1px solid #ccc"
                            borderRadius="4px"
                            p={1}
                            m={1}
                        >
                            <Typography>{part}</Typography>
                            <IconButton size="small" onClick={() => handleRemovePart(index)}>
                                <DeleteIcon fontSize="small" />
                            </IconButton>
                        </Box>
                    ))}
                </Box>
            </Box>

            {/* Button to finalize the constructed URL */}
            <Button onClick={handleConstructURL} variant="contained"
                    color="primary"
                    className="button" style={{ marginTop: '10px' }}>
                Done
            </Button>
        </Box>
    );
};

export default URLConstructor;
