import React, { useState, useEffect } from 'react';
import { Button, TextField, Typography, FormControl, List, ListItem, ListItemText, IconButton, Grid } from '@mui/material';
import { db } from './firebase';
import { doc, updateDoc, getDoc } from 'firebase/firestore';
import DeleteIcon from '@mui/icons-material/Delete';

const PredefinedRulesManager = () => {
    const [predefinedRules, setPredefinedRules] = useState([]);
    const [newRule, setNewRule] = useState({ id: '', senderDomain: '', linkPattern: '', downloadUrlTemplate: '', actions: [] });
    const [selectedRuleId, setSelectedRuleId] = useState('');
    const [newAction, setNewAction] = useState({ selector: '', type: '' });

    useEffect(() => {
        fetchPredefinedRules();
    }, []);

    const fetchPredefinedRules = async () => {
        const globalSettingsRef = doc(db, 'globalsettings', 'rules');
        const docSnap = await getDoc(globalSettingsRef);
        if (docSnap.exists()) {
            setPredefinedRules(docSnap.data().rules || []);
        } else {
            console.log('No predefined rules found in Firestore.');
        }
    };

    const handleInputChange = (field, value) => {
        setNewRule(prev => ({ ...prev, [field]: value }));
    };

    const handleActionChange = (field, value) => {
        setNewAction(prev => ({ ...prev, [field]: value }));
    };

    const handleAddAction = () => {
        if (newAction.selector && newAction.type) {
            setNewRule(prev => ({ ...prev, actions: [...prev.actions, newAction] }));
            setNewAction({ selector: '', type: '' });
        }
    };

    const handleDeleteAction = (index) => {
        setNewRule(prev => ({
            ...prev,
            actions: prev.actions.filter((_, i) => i !== index)
        }));
    };

    const handleAddRule = async () => {
        const ruleWithId = { ...newRule, id: new Date().getTime().toString() };
        const updatedRules = [...predefinedRules, ruleWithId];

        const globalSettingsRef = doc(db, 'globalsettings', 'rules');
        try {
            await updateDoc(globalSettingsRef, { rules: updatedRules });
            setPredefinedRules(updatedRules);
            setNewRule({ id: '', senderDomain: '', linkPattern: '', downloadUrlTemplate: '', actions: [] });
            console.log('Predefined rule added successfully.');
        } catch (error) {
            console.error('Failed to add predefined rule', error);
        }
    };

    const handleDeleteRule = async (ruleId) => {
        const updatedRules = predefinedRules.filter(rule => rule.id !== ruleId);

        const globalSettingsRef = doc(db, 'globalsettings', 'rules');
        try {
            await updateDoc(globalSettingsRef, { rules: updatedRules });
            setPredefinedRules(updatedRules);
            console.log('Predefined rule deleted successfully.');
        } catch (error) {
            console.error('Failed to delete predefined rule', error);
        }
    };

    const handleEditRule = (ruleId) => {
        const ruleToEdit = predefinedRules.find(rule => rule.id === ruleId);
        if (ruleToEdit) {
            setNewRule({
                id: ruleToEdit.id,
                senderDomain: ruleToEdit.senderDomain,
                linkPattern: ruleToEdit.linkPattern,
                downloadUrlTemplate: ruleToEdit.downloadUrlTemplate,
                actions: ruleToEdit.actions || [],
            });
            setSelectedRuleId(ruleId);
        }
    };

    const handleUpdateRule = async () => {
        const updatedRules = predefinedRules.map(rule =>
            rule.id === selectedRuleId
                ? newRule
                : rule
        );

        const globalSettingsRef = doc(db, 'globalsettings', 'rules');
        try {
            await updateDoc(globalSettingsRef, { rules: updatedRules });
            setPredefinedRules(updatedRules);
            setNewRule({ id: '', senderDomain: '', linkPattern: '', downloadUrlTemplate: '', actions: [] });
            setSelectedRuleId('');
            console.log('Predefined rule updated successfully.');
        } catch (error) {
            console.error('Failed to update predefined rule', error);
        }
    };

    return (
      <div className="card">
        <Typography variant="h6" className="typography">
          Manage Predefined Rules
        </Typography>
        <List>
          {predefinedRules.map((rule) => (
            <ListItem key={rule.id}>
              <ListItemText
                primary={rule.senderDomain}
                secondary={
                  <>
                    <Typography>Pattern: {rule.linkPattern}</Typography>
                    <Typography>
                      Download URL: {rule.downloadUrlTemplate}
                    </Typography>
                    <Typography variant="subtitle1">Actions:</Typography>
                    <ul>
                      {(Array.isArray(rule.actions) ? rule.actions : []).map(
                        (action, index) => (
                          <li key={index}>
                            <Typography variant="body2">
                              <strong>Selector:</strong> {action.selector},{" "}
                              <strong>Type:</strong> {action.type}
                            </Typography>
                          </li>
                        )
                      )}
                    </ul>
                  </>
                }
              />
              <IconButton
                edge="end"
                aria-label="edit"
                onClick={() => handleEditRule(rule.id)}
              >
                <i className="material-icons">edit</i>
              </IconButton>
              <IconButton
                edge="end"
                aria-label="delete"
                onClick={() => handleDeleteRule(rule.id)}
              >
                <DeleteIcon />
              </IconButton>
            </ListItem>
          ))}
        </List>

        <FormControl fullWidth className="form-control">
          <TextField
            label="Sender Domain"
            value={newRule.senderDomain}
            onChange={(e) => handleInputChange("senderDomain", e.target.value)}
            fullWidth
            className="text-field"
          />
          <TextField
            label="Link Pattern"
            value={newRule.linkPattern}
            onChange={(e) => handleInputChange("linkPattern", e.target.value)}
            fullWidth
            className="text-field"
          />
          <TextField
            label="Download URL Template"
            value={newRule.downloadUrlTemplate}
            onChange={(e) =>
              handleInputChange("downloadUrlTemplate", e.target.value)
            }
            fullWidth
            className="text-field"
          />

          <Typography variant="h6">Actions</Typography>
          {newRule.actions.map((action, index) => (
            <Grid container key={index} spacing={2} alignItems="center">
              <Grid item xs={5}>
                <TextField
                  label="Selector"
                  value={action.selector}
                  onChange={(e) =>
                    handleActionChange("selector", e.target.value)
                  }
                  fullWidth
                />
              </Grid>
              <Grid item xs={5}>
                <TextField
                  label="Type"
                  value={action.type}
                  onChange={(e) => handleActionChange("type", e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={2}>
                <IconButton
                  aria-label="delete"
                  onClick={() => handleDeleteAction(index)}
                >
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </Grid>
          ))}

          <Grid container spacing={2}>
            <Grid item xs={5}>
              <TextField
                label="Action Selector"
                value={newAction.selector}
                onChange={(e) => handleActionChange("selector", e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={5}>
              <TextField
                label="Action Type"
                value={newAction.type}
                onChange={(e) => handleActionChange("type", e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={2}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleAddAction}
              >
                Add Action
              </Button>
            </Grid>
          </Grid>

          <Button
            onClick={selectedRuleId ? handleUpdateRule : handleAddRule}
            variant="contained"
            color="primary"
            className="button"
          >
            {selectedRuleId ? "Update Rule" : "Add Rule"}
          </Button>
        </FormControl>
      </div>
    );
};

export default PredefinedRulesManager;
