import React, { useState, useEffect, useCallback } from 'react';
import {
    Button, List, ListItem, ListItemText, ListItemIcon, Typography, Collapse, Checkbox, TextField, Box
} from '@mui/material';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import FolderIcon from '@mui/icons-material/Folder';
import AddBoxIcon from '@mui/icons-material/AddBox';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import { db } from './firebase';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { Client } from '@microsoft/microsoft-graph-client';
import './App.css'; // Import the CSS file

const SharePointFolderSelector = ({ accessToken, selectedExtractionFolder, onCompleted, userId }) => {
    const [selectedFolder, setSelectedFolder] = useState(null);
    const [selectedSite, setSelectedSite] = useState(null);
    const [selectedLibrary, setSelectedLibrary] = useState(null);
    const [sites, setSites] = useState([]);
    const [libraries, setLibraries] = useState([]);
    const [folders, setFolders] = useState([]);
    const [siteUrl, setSiteUrl] = useState('');
    const [showUrlSearch, setShowUrlSearch] = useState(false);
    const [view, setView] = useState('sites'); // 'sites', 'libraries', 'folders'

    const getClient = useCallback(() => {
        return Client.init({
            authProvider: (done) => done(null, accessToken),
        });
    }, [accessToken]);

    const fetchCurrentFolderPairs = useCallback(async () => {
        const settingsRef = doc(db, 'settings', userId); 
        try {
            const docSnap = await getDoc(settingsRef);
            if (docSnap.exists()) {
                const folderPairs = docSnap.data().folderPairs || [];
                console.log('Folder pairs fetched:', folderPairs);
            } else {
                console.log('No such document in Firestore.');
            }
        } catch (error) {
            console.error('Failed to fetch current folder pairs', error);
        }
    }, [userId]);

    const fetchSites = useCallback(async () => {
        try {
            const client = getClient();
            const response = await client.api('/sites?search=*').get();
            setSites(response.value);
        } catch (error) {
            console.error('Failed to fetch sites', error);
        }
    }, [getClient]);

    useEffect(() => {
        if (!selectedExtractionFolder) return;
        fetchCurrentFolderPairs();
        fetchSites();
    }, [selectedExtractionFolder, fetchCurrentFolderPairs, fetchSites]);

    const fetchSiteByUrl = async () => {
        try {
            const client = getClient();
            const response = await client.api(`/sites/${siteUrl}`).get();
            setSites([response]);
        } catch (error) {
            console.error('Failed to fetch site by URL', error);
        }
    };

    const getFolderPath = (folder, parentPath = [], libraryName = '') => {
        if (!folder) return parentPath;
        return [...parentPath, libraryName, folder.name].filter(Boolean);
    };

    const handleSelectFolder = (folder, parentPath = [], libraryName = '') => {
        const path = getFolderPath(folder, parentPath, libraryName);
        setSelectedFolder({ ...folder, path });
    };

    const handleSelectLibrary = async (library) => {
        try {
            const client = getClient();
            const response = await client.api(`/drives/${library.id}/root/children`).get();
            setFolders(response.value);
            setSelectedLibrary(library);
            setView('folders');
        } catch (error) {
            console.error('Failed to fetch library folders', error);
        }
    };

    const handleSelectSite = async (site) => {
        try {
            const client = getClient();
            const response = await client.api(`/sites/${site.id}/drives`).get();
            setLibraries(response.value);
            setFolders([]);
            setSelectedSite(site);
            setView('libraries');
        } catch (error) {
            console.error('Failed to fetch site libraries', error);
        }
    };

    const handleConfirmSelect = async () => {
        const settingsRef = doc(db, 'settings', userId);
        const selectedFolderData = {
            id: selectedFolder.id,
            name: selectedFolder.name,
            path: selectedFolder.path.join('/'),
            siteId: selectedSite.id,
            driveId: selectedLibrary.id,
            sharePointFolderId: selectedFolder.id,
        };

        try {
            const docSnap = await getDoc(settingsRef);
            if (docSnap.exists()) {
                const folderPairs = docSnap.data().folderPairs || [];
                const updatedFolderPairs = folderPairs.map(pair => {
                    if (pair.extractionFolder.id === selectedExtractionFolder.id) {
                        return {
                            ...pair,
                            extractionFolder: {
                                ...pair.extractionFolder,
                                selectedSharePointFolder: selectedFolderData
                            }
                        };
                    }
                    return pair;
                });
                await updateDoc(settingsRef, {
                    folderPairs: updatedFolderPairs,
                });
            } else {
                console.log('No such document in Firestore.');
            }
            onCompleted();
        } catch (error) {
            console.error('Failed to save selected folder', error);
        }
    };

    const RecursiveFolderItem = ({ folder, level = 0, parentPath = [], libraryName = '' }) => {
        const [open, setOpen] = useState(false);
        const toggleOpen = () => setOpen(!open);

        const isSelected = selectedFolder && selectedFolder.id === folder.id;
        const path = getFolderPath(folder, parentPath, libraryName);

        return (
            <>
                <ListItem button onClick={() => handleSelectFolder(folder, parentPath, libraryName)} sx={{ pl: `${2 * level}rem` }} className="list-item">
                    <Checkbox checked={!!isSelected} />
                    {folder.folder && folder.folder.childCount > 0 && (
                        <ListItemIcon onClick={(e) => {
                            e.stopPropagation();
                            toggleOpen();
                        }} className="list-item-icon">
                            {open ? <IndeterminateCheckBoxIcon /> : <AddBoxIcon />}
                        </ListItemIcon>
                    )}
                    <ListItemIcon className="list-item-icon">
                        {open ? <FolderOpenIcon /> : <FolderIcon />}
                    </ListItemIcon>
                    <ListItemText primary={folder.name} className="list-item-text" />
                </ListItem>
                {folder.folder && folder.folder.childCount > 0 && (
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            {folder.children && folder.children.map((child) => (
                                <RecursiveFolderItem key={child.id} folder={child} level={level + 1} parentPath={path} libraryName={libraryName} />
                            ))}
                        </List>
                    </Collapse>
                )}
            </>
        );
    };

    return (
        <div>
            <Box sx={{ border: '1px solid #ccc', borderRadius: '4px', p: 2, mb: 2 }} className="box">
                <Typography variant="h6" className="typography">
                    Select a SharePoint Folder to save attachments extracted from {selectedExtractionFolder && `${selectedExtractionFolder.fullPath}`}
                </Typography>
                {view === 'sites' && (
                    <>
                        <Typography variant="h6" className="typography">Search for a Site</Typography>
                        <Button onClick={() => {
                            if (showUrlSearch) {
                                fetchSites();
                                setShowUrlSearch(false);
                            } else {
                                setShowUrlSearch(true);
                                setSites([]);
                            }
                        }} className="button">
                            {showUrlSearch ? 'Show All Sites' : 'Search by URL'}
                        </Button>
                        {showUrlSearch && (
                            <>
                                <TextField
                                    label="Site URL (without https://)"
                                    value={siteUrl}
                                    onChange={(e) => setSiteUrl(e.target.value)}
                                    helperText="Enter the exact site URL without https:// (e.g., example.sharepoint.com)"
                                    fullWidth
                                    sx={{ mt: 2 }}
                                    className="text-field"
                                />
                                <Button onClick={fetchSiteByUrl} className="button">Search</Button>
                            </>
                        )}
                        <Typography variant="h6" className="typography">Sites</Typography>
                        <List className="list">
                            {sites.map(site => (
                                <ListItem button onClick={() => handleSelectSite(site)} key={site.id} className="list-item">
                                    <ListItemText primary={site.webUrl} className="list-item-text" />
                                </ListItem>
                            ))}
                        </List>
                    </>
                )}
                {view === 'libraries' && (
                    <>
                        <Button onClick={() => setView('sites')} className="button">Back to Sites</Button>
                        <Typography variant="h6" className="typography">Libraries</Typography>
                        <List className="list">
                            {libraries.map(library => (
                                <ListItem button onClick={() => handleSelectLibrary(library)} key={library.id} className="list-item">
                                    <ListItemIcon className="list-item-icon">
                                        <FolderIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={library.name} className="list-item-text" />
                                </ListItem>
                            ))}
                        </List>
                    </>
                )}
                {view === 'folders' && (
                    <>
                        <Button onClick={() => setView('libraries')} className="button">Back to Libraries</Button>
                        <Typography variant="h6" className="typography">Folders</Typography>
                        <List className="list">
                            {folders.map(folder => (
                                <RecursiveFolderItem key={folder.id} folder={folder} libraryName={selectedLibrary.name} />
                            ))}
                        </List>
                    </>
                )}
            </Box>
            {selectedFolder && (
                <Typography variant="subtitle1" sx={{ mb: 2 }} className="typography">
                    Selected Folder: {selectedSite.webUrl}/{selectedFolder.path.join('/')}
                </Typography>
            )}
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} className="box">
                <Button onClick={handleConfirmSelect} disabled={!selectedFolder} variant="contained" className="button">Confirm Selection</Button>
            </Box>
        </div>
    );
};

export default SharePointFolderSelector;
