import React, { useState, useEffect } from 'react';
import { Button, Typography, MenuItem, Select, FormControl, InputLabel, Dialog, DialogContent, DialogTitle, Box, Grid, Paper, DialogActions, Divider, RadioGroup, Radio, FormControlLabel } from '@mui/material';
import { db } from './firebase';
import { doc, updateDoc, getDoc, setDoc } from 'firebase/firestore';
import DownloadRuleRecorder from './DownloadRuleRecorder';
import AddNewDownloadRule from './AddNewDownloadRule';
import PredefinedRuleSelector from './PredefinedRuleSelector';
import './App.css';

const DownloadRulePicker = ({ userId }) => {
    const [folders, setFolders] = useState([]);
    const [selectedFolder, setSelectedFolder] = useState('');
    const [rules, setRules] = useState({});
    const [newRule, setNewRule] = useState({ senderDomain: '', urls: ['', '', '', '', ''], downloadUrl: '', actions: [] });
    const [generatedPattern, setGeneratedPattern] = useState('');
    const [downloadUrlTemplate, setDownloadUrlTemplate] = useState('');
    const [regexPattern, setRegexPattern] = useState('');
    const [showAddRule, setShowAddRule] = useState(false);
    const [predefinedRules, setPredefinedRules] = useState([]);
    const [selectedPredefinedRule, setSelectedPredefinedRule] = useState('');
    const [matchingRule, setMatchingRule] = useState('whole');
    const [ruleType, setRuleType] = useState('custom'); 
    const [dialogOpen, setDialogOpen] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [deleteIndex, setDeleteIndex] = useState(null);

    // To open the rule recorder dialog
    const handleOpenRecorder = () => {
        setDialogOpen(true);
    };

    const handleCloseRecorder = () => {
        setDialogOpen(false);
    };

    const handleRecordedSteps = (steps) => {
        console.log('Recorded steps:', steps); // Log the recorded steps
        setNewRule(prev => ({ ...prev, actions: steps })); // Save the recorded actions to newRule
        setDialogOpen(false); // Close the recorder dialog
    };

    const handleAddRule = async (newRule, finalConstructedURL, addToPredefined) => {
        const finalDownloadUrlTemplate = finalConstructedURL || newRule.downloadUrlTemplate;

        const newRuleWithPattern = {
            senderDomain: newRule.senderDomain,
            linkPattern: regexPattern || newRule.linkPattern,
            downloadUrlTemplate: finalDownloadUrlTemplate,  // Use the final constructed URL
            actions: newRule.actions
        };

        const updatedRules = [...(rules[selectedFolder] || []), newRuleWithPattern];

        try {
            const settingsRef = doc(db, 'settings', userId);
            const docSnap = await getDoc(settingsRef);
            if (docSnap.exists()) {
                const currentData = docSnap.data();
                const updatedFolderPairs = currentData.folderPairs.map(pair => {
                    if (pair.extractionFolder.id === selectedFolder) {
                        return {
                            ...pair,
                            extractionFolder: {
                                ...pair.extractionFolder,
                                downloadRules: updatedRules
                            }
                        };
                    }
                    return pair;
                });

                await updateDoc(settingsRef, {
                    folderPairs: updatedFolderPairs,
                });

                if (addToPredefined) {
                    const globalSettingsRef = doc(db, 'globalsettings', 'rules');
                    const globalDocSnap = await getDoc(globalSettingsRef);

                    if (globalDocSnap.exists()) {
                        const currentPredefinedRules = globalDocSnap.data().rules || [];
                        const updatedPredefinedRules = [...currentPredefinedRules, newRuleWithPattern];

                        await updateDoc(globalSettingsRef, { rules: updatedPredefinedRules });
                    } else {
                        const newPredefinedRules = [newRuleWithPattern];
                        await setDoc(globalSettingsRef, { rules: newPredefinedRules });
                    }
                }

                setRules(prev => ({
                    ...prev,
                    [selectedFolder]: updatedRules
                }));
                console.log('Custom rule added and Firestore updated successfully.');
            } else {
                console.log('No such document in Firestore.');
            }
        } catch (error) {
            console.error('Failed to add custom rule to Firestore', error);
        }

        // Reset state for custom rule
        setNewRule({ senderDomain: '', urls: ['', '', '', '', ''], downloadUrl: '', actions: [] });
        setShowAddRule(false);
    };

    useEffect(() => {
        const fetchFolders = async () => {
            const settingsRef = doc(db, 'settings', userId);
            const docSnap = await getDoc(settingsRef);
            if (docSnap.exists()) {
                const folderPairs = docSnap.data().folderPairs || [];
                const extractionFolders = folderPairs.map(pair => pair.extractionFolder);
                setFolders(extractionFolders);

                const initialRules = {};
                extractionFolders.forEach(folder => {
                    initialRules[folder.id] = folder.downloadRules || [];
                });
                setRules(initialRules);

                if (extractionFolders.length > 0) {
                    setSelectedFolder(extractionFolders[0].id);
                }
            } else {
                console.log('No such document in Firestore.');
            }
        };

        const fetchPredefinedRules = async () => {
            const globalSettingsRef = doc(db, 'globalsettings', 'rules');
            const docSnap = await getDoc(globalSettingsRef);
            if (docSnap.exists()) {
                setPredefinedRules(docSnap.data().rules || []);
            } else {
                console.log('No predefined rules found in Firestore.');
            }
        };

        fetchFolders();
        fetchPredefinedRules();
    }, [userId]);

    const handleFolderChange = (event) => {
        const folderId = event.target.value;
        setSelectedFolder(folderId);
    };

    const handleDeleteRule = (index) => {
        setDeleteIndex(index);
        setDeleteDialogOpen(true);
    };

    const confirmDeleteRule = async () => {
        const updatedRules = rules[selectedFolder].filter((_, i) => i !== deleteIndex);

        try {
            const settingsRef = doc(db, 'settings', userId);
            const docSnap = await getDoc(settingsRef);
            if (docSnap.exists()) {
                const currentData = docSnap.data();
                const updatedFolderPairs = currentData.folderPairs.map(pair => {
                    if (pair.extractionFolder.id === selectedFolder) {
                        return {
                            ...pair,
                            extractionFolder: {
                                ...pair.extractionFolder,
                                downloadRules: updatedRules
                            }
                        };
                    }
                    return pair;
                });

                await updateDoc(settingsRef, {
                    folderPairs: updatedFolderPairs,
                });

                setRules(prev => ({
                    ...prev,
                    [selectedFolder]: updatedRules
                }));
                console.log('Rule deleted and Firestore updated successfully.');
            } else {
                console.log('No such document in Firestore.');
            }
        } catch (error) {
            console.error('Failed to delete rule from Firestore', error);
        }

        setDeleteDialogOpen(false);
        setDeleteIndex(null);
    };

    const cancelDeleteRule = () => {
        setDeleteDialogOpen(false);
        setDeleteIndex(null);
    };

    return (
        <Box component={Paper} p={3} className="card">
            <FormControl fullWidth className="form-control">
                <InputLabel>Select Folder</InputLabel>
                <Select value={selectedFolder} onChange={handleFolderChange} className="select">
                    {folders.map(folder => (
                        <MenuItem key={folder.id} value={folder.id} className="menu-item">
                            {folder.fullPath}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            <Typography variant="h6" className="typography">Download Rules for {folders.find(f => f.id === selectedFolder)?.fullPath}</Typography>

            {rules[selectedFolder]?.map((rule, index) => (
                <Box key={index} mb={2} className="rule">
                    <Grid container alignItems="center" spacing={2}>
                        <Grid item xs={10}>
                            <Typography
                              variant="body1"
                              className="typography"
                              style={{ wordWrap: 'break-word', whiteSpace: 'pre-wrap' }}
                            >
                                {index + 1}. Domain: {rule.senderDomain}, Pattern: {rule.linkPattern}, Template: {rule.downloadUrlTemplate}
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Button variant="contained" color="secondary" onClick={() => handleDeleteRule(index)} className="button">Delete</Button>
                        </Grid>
                    </Grid>
                    <Divider />
                </Box>
            ))}

            <Button onClick={() => setShowAddRule(true)} variant="contained" color="primary" className="button">Add New Rule</Button>

            {showAddRule && (
                <Box mt={2}>
                    <FormControl component="fieldset">
                        <Typography variant="subtitle1">Rule Type</Typography>
                        <RadioGroup
                            value={ruleType}
                            onChange={(e) => setRuleType(e.target.value)}
                            row
                        >
                            <FormControlLabel value="custom" control={<Radio />} label="Custom Rule" />
                            <FormControlLabel value="predefined" control={<Radio />} label="Predefined Rule" />
                        </RadioGroup>
                    </FormControl>

                    {/* Conditionally render the custom rule or predefined rule form */}
                    {ruleType === 'custom' ? (
                        <AddNewDownloadRule
                            predefinedRules={predefinedRules}
                            newRule={newRule}
                            setNewRule={setNewRule}
                            generatedPattern={generatedPattern}
                            setGeneratedPattern={setGeneratedPattern}
                            downloadUrlTemplate={downloadUrlTemplate}
                            setDownloadUrlTemplate={setDownloadUrlTemplate}
                            regexPattern={regexPattern}
                            setRegexPattern={setRegexPattern}
                            matchingRule={matchingRule}
                            setMatchingRule={setMatchingRule}
                            selectedPredefinedRule={selectedPredefinedRule}
                            setSelectedPredefinedRule={setSelectedPredefinedRule}
                            onAddRule={handleAddRule}
                        />
                    ) : (
                        <PredefinedRuleSelector
                            predefinedRules={predefinedRules}
                            selectedPredefinedRule={selectedPredefinedRule}
                            setSelectedPredefinedRule={setSelectedPredefinedRule}
                            onAddRule={handleAddRule}
                        />
                    )}

                    {/* Button to open rule recorder */}
                    <Button onClick={handleOpenRecorder} variant="contained" color="primary" className="button">
                        Record Actions
                    </Button>
                </Box>
            )}

            <Dialog open={dialogOpen} onClose={handleCloseRecorder} fullWidth maxWidth="lg">
                <DialogContent style={{ maxHeight: '600px', overflowY: 'auto' }}>
                    <DownloadRuleRecorder onRecordedSteps={handleRecordedSteps} newRule={newRule} />
                </DialogContent>
            </Dialog>

            <Dialog open={deleteDialogOpen} onClose={cancelDeleteRule}>
                <DialogTitle>Confirm Deletion</DialogTitle>
                <DialogContent>
                    <Typography>Are you sure you want to delete this rule?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={cancelDeleteRule} color="primary">Cancel</Button>
                    <Button onClick={confirmDeleteRule} color="secondary">Delete</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default DownloadRulePicker;
