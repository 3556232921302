import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Container, Typography, Box, CircularProgress, Paper, Divider, Dialog, Chip } from '@mui/material';
import ConfirmationPopper from './ConfirmationPopper';
import { db } from './firebase';
import { doc, getDoc, deleteDoc } from 'firebase/firestore';

const ServerControl = ({ baseUrl, username, allowed, onLogsVisibilityChange }) => {
    const [status, setStatus] = useState('loading');
    const [loading, setLoading] = useState(true);
    const [logs, setLogs] = useState([]);
    const [confirmationOpen, setConfirmationOpen] = useState(false);
    const [confirmationMessage, setConfirmationMessage] = useState('');
    const [confirmAction, setConfirmAction] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [logsVisible, setLogsVisible] = useState(false);
    const [serviceUsername, setServiceUsername] = useState('');
    const [loginMessage, setLoginMessage] = useState('');
    const [subscriptionKey, setSubscriptionKey] = useState('');
    const [logsDialogOpen, setLogsDialogOpen] = useState(false);

    useEffect(() => {
        const fetchSubscriptionKey = async () => {
            const apiConfigDocRef = doc(db, 'config', 'keys');
            try {
                const docSnap = await getDoc(apiConfigDocRef);
                if (docSnap.exists()) {
                    setSubscriptionKey(docSnap.data().subscriptionKey);
                } else {
                    console.error('No API subscription key document found!');
                }
            } catch (error) {
                console.error('Error fetching API subscription key:', error);
            }
        };

        fetchSubscriptionKey();
    }, []);

    const axiosInstance = axios.create({
        baseURL: baseUrl,
        headers: {
            'Content-Type': 'application/json',
            'Ocp-Apim-Subscription-Key': subscriptionKey,
        },
    });

    const fetchServiceUsername = async () => {
        const serviceUsernameDocRef = doc(db, 'serviceUsername', 'current');
        try {
            const docSnap = await getDoc(serviceUsernameDocRef);
            if (docSnap.exists()) {
                setServiceUsername(docSnap.data().username);
            } else {
                console.error('No service username document found!');
            }
        } catch (error) {
            console.error('Error fetching service username:', error);
        }
    };

    const fetchStatus = async () => {
        setLoading(true);
        try {
            const response = await axiosInstance.get('/status');
            setStatus(response.data.status);
        } catch (error) {
            console.error('Error fetching server status:', error);
            setStatus('error');
        } finally {
            setLoading(false);
        }
    };

    const getStatusColor = (status) => {
        switch (status) {
          case "online":
            return "success";
          case "offline":
            return "error";
          case "loading":
            return "warning";
          case "error":
            return "error";
          default:
            return "default";
        }
      };

      const getChipLabel = () => {
        return status;
      };

    const fetchLogs = async () => {
        try {
            const response = await axiosInstance.get('/logs');
            const formattedLogs = response.data.logs.split('\n').map((line, index) => ({
                id: index,
                message: line,
                isError: line.toLowerCase().includes('error') || line.toLowerCase().includes('failed'),
            }));
            setLogs(formattedLogs);
        } catch (error) {
            console.error('Error fetching logs:', error);
            setLogs([{ id: 0, message: 'Error fetching logs', isError: true }]);
        }
    };

    useEffect(() => {
        if (baseUrl && subscriptionKey) {
            fetchStatus();
            fetchServiceUsername();
        }
    }, [baseUrl, subscriptionKey]);

    useEffect(() => {
        if (username && serviceUsername && username !== serviceUsername) {
            setLoginMessage(`You need to log in as ${username}`);
            restartServer();
            deleteServiceUsername();
        }
    }, [username, serviceUsername]);

    const deleteServiceUsername = async () => {
        const serviceUsernameDocRef = doc(db, 'serviceUsername', 'current');
        try {
            await deleteDoc(serviceUsernameDocRef);
            console.log('Service username document deleted.');
        } catch (error) {
            console.error('Error deleting service username document:', error);
        }
    };

    const startServer = async () => {
        setLoading(true);
        try {
            const response = await axiosInstance.post('/start');
            console.log(response.data.message);
            fetchStatus();
            fetchLogs();
        } catch (error) {
            console.error('Error starting server:', error);
            setStatus('error');
        }
    };

    const stopServer = async () => {
        setLoading(true);
        try {
            const response = await axiosInstance.post('/stop');
            console.log(response.data.message);
            fetchStatus();
            fetchLogs();
        } catch (error) {
            console.error('Error stopping server:', error);
            setStatus('error');
        }
    };

    const restartServer = async () => {
        setLoading(true);
        try {
            const response = await axiosInstance.post('/restart');
            console.log(response.data.message);
            fetchStatus();
            fetchLogs();
        } catch (error) {
            console.error('Error restarting server:', error);
            setStatus('error');
        }
    };

    const handleOpenConfirmation = (event, action, message) => {
        setAnchorEl(event.currentTarget);
        setConfirmAction(() => action);
        setConfirmationMessage(message);
        setConfirmationOpen(true);
    };

    const handleConfirm = () => {
        if (confirmAction) confirmAction();
        setConfirmationOpen(false);
    };

    const handleCancel = () => {
        setConfirmationOpen(false);
    };

    const handleLogsButtonClick = () => {
        if (allowed) {
            if (!logsVisible) {
                fetchLogs();
            }

            setLogsVisible(!logsVisible);
            setLogsDialogOpen(!logsDialogOpen);
            if (onLogsVisibilityChange) {
                onLogsVisibilityChange(!logsVisible);
            }
        } else {
            alert('Access Denied: You do not have permission to view logs.');
        }
    };

    return (
      <Container>
        <Box sx={{ my: 4 }}>
          {loading ? (
            <CircularProgress />
          ) : (
            <Box sx={{ mb: 3 }}>
  <Box
    component="fieldset"
    sx={{
      border: "1px solid #ccc",
      borderRadius: "8px",
      padding: "16px",
      position: "relative",
      marginBottom: 2,
    }}
  >
    <Typography
      component="legend"
      variant="subtitle2"
      sx={{
        position: "absolute",
        top: "-10px",
        left: "16px",
        backgroundColor: "white",
        padding: "0 8px",
      }}
    >
      Service User
    </Typography>
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontWeight: "bold",
        fontSize: "1.1em",
      }}
    >
      {serviceUsername}
    </Box>
  </Box>

  <Box
    component="fieldset"
    sx={{
      border: "1px solid #ccc",
      borderRadius: "8px",
      padding: "16px",
      position: "relative",
    }}
  >
    <Typography
      component="legend"
      variant="subtitle2"
      sx={{
        position: "absolute",
        top: "-10px",
        left: "16px",
        backgroundColor: "white",
        padding: "0 8px",
      }}
    >
      Extractor Status
    </Typography>
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Chip
        label={
          <Typography
            variant="body2"
            sx={{ color: "inherit", textTransform: "uppercase", fontWeight: "bold" }}
          >
            {getChipLabel()}
          </Typography>
        }
        color={getStatusColor(status)}
        sx={{
          cursor: "pointer",
          minWidth: 120,
          height: 40,
          fontSize: "0.9em",
        }}
      />
    </Box>
  </Box>
</Box>

          )}
          {loginMessage && (
            <Typography variant="body1" color="error" gutterBottom>
              {loginMessage}
            </Typography>
          )}
          <Box sx={{ display: "flex", gap: 2 }}>
    <Button
      variant="contained"
      color="success"
      onClick={startServer}
      disabled={loading || status === "online"}
    >
      Start Service
    </Button>
    <Button
      variant="contained"
      color="error"
      onClick={(e) =>
        handleOpenConfirmation(
          e,
          stopServer,
          "Are you sure you want to stop the server?"
        )
      }
      disabled={loading || status !== "online"}
    >
      Stop Service
    </Button>
    <Button
      variant="contained"
      color="warning"
      onClick={(e) =>
        handleOpenConfirmation(
          e,
          restartServer,
          "Are you sure you want to restart the server?"
        )
      }
      disabled={loading || status !== "online"}
    >
      Restart Service
    </Button>
  </Box>
          <Box sx={{ mt: 4 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleLogsButtonClick}
              sx={{ m: 1 }}
            >
              {logsVisible ? "Refresh Logs" : "View Logs"}
            </Button>
            <Dialog
              open={logsDialogOpen}
              onClose={() => setLogsDialogOpen(false)}
              fullWidth
              maxWidth="lg"
            >
              <Paper
                elevation={3}
                sx={{
                  p: 3,
                  maxHeight: 600,
                  overflow: "auto",
                  width: "100%",
                }}
              >
                {logs.map((log) => (
                  <Box key={log.id} sx={{ my: 1 }}>
                    <Typography
                      variant="body2"
                      component="pre"
                      sx={{
                        color: log.isError ? "error.main" : "text.primary",
                      }}
                    >
                      {log.message}
                    </Typography>
                    <Divider />
                  </Box>
                ))}
              </Paper>
            </Dialog>
          </Box>
        </Box>
        <ConfirmationPopper
          open={confirmationOpen}
          anchorEl={anchorEl}
          onConfirm={handleConfirm}
          onCancel={handleCancel}
          message={confirmationMessage}
        />
      </Container>
    );
};

export default ServerControl;
