import React, { useEffect, useState } from 'react';

const CompareUrls = ({ urls, onGenerateRegex = () => {}, matchingRule }) => {  // Add matchingRule prop
  const [regexPattern, setRegexPattern] = useState('');

  useEffect(() => {
    if (urls.length < 2) {
      console.log("Please provide at least two URLs to compare.");
      return;
    }

    // Generate the regex pattern from the URL comparisons
    const pattern = generateRegexPattern(urls);
    const adjustedPattern = applyMatchingRule(pattern);  // Apply matching rule before passing back
    setRegexPattern(adjustedPattern);
    onGenerateRegex(adjustedPattern);  // Pass the generated pattern back to the parent component
  }, [urls, onGenerateRegex, matchingRule]);

  const generateRegexPattern = (urls) => {
    // Split URLs into arrays of words (sections) for comparison
    const splitUrls = urls.map(url => url.split(/([A-Za-z0-9_-]+)/));

    // Initialize regex pattern
    let regexPattern = '';

    // Compare each part across all URLs and build the regex pattern
    for (let i = 0; i < splitUrls[0].length; i++) {
      const partSet = new Set(splitUrls.map(parts => parts[i]));

      if (partSet.size === 1 && partSet.has(splitUrls[0][i])) {
        // If all URLs have the same part, add it as a static part in the regex
        regexPattern += escapeRegex(splitUrls[0][i]);
      } else {
        // If the part is different, replace it with a regex to match variable characters
        regexPattern += '([A-Za-z0-9_-]+)';
      }
    }

    return regexPattern;
  };

  // Helper function to apply the matching rule
  const applyMatchingRule = (regexPattern) => {
    switch (matchingRule) {
      case 'whole':
        return `^${regexPattern}$`; // Match the entire URL
      case 'start':
        return `^${regexPattern}`; // Match the start of the URL
      case 'end':
        return `${regexPattern}$`; // Match the end of the URL
      case 'any':
        return regexPattern; // Match any part of the URL
      default:
        return regexPattern;
    }
  };

  // Helper function to escape special regex characters
  const escapeRegex = (text) => {
    return text.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
  };

  return (
    <div>
      <h3>Generated Regex Pattern:</h3>
      <p>{regexPattern}</p>
    </div>
  );
};

export default CompareUrls;
