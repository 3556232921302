import React, { useState, useEffect } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItem,
  TextField,
  IconButton,
  CircularProgress,
  Typography,
  Divider,
  Box,
  ListItemText,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import { db } from './firebase';
import { doc, getDoc, updateDoc, arrayRemove } from 'firebase/firestore';
import { useAuth } from './AuthContext';
import ExtractionSetupStepper from './ExtractionSetupStepper'; // Import the stepper component
import './App.css'; // Import the CSS file

const FolderList = ({ accessToken }) => {
  const [folderPairs, setFolderPairs] = useState([]);
  const [loading, setLoading] = useState(true);
  const { officeUser } = useAuth();
  const [editMode, setEditMode] = useState({});
  const [updatedRules, setUpdatedRules] = useState({});
  const [warningDialogOpen, setWarningDialogOpen] = useState(false);
  const [pendingRuleIndex, setPendingRuleIndex] = useState(null);
  const [pendingPair, setPendingPair] = useState(null);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [selectedPair, setSelectedPair] = useState(null);

  useEffect(() => {
    if (officeUser) {
      fetchCurrentFolders();
    }
  }, [officeUser]);

  const fetchCurrentFolders = async () => {
    setLoading(true);
    const settingsRef = doc(db, 'settings', officeUser.username);
    try {
      const docSnap = await getDoc(settingsRef);
      if (docSnap.exists() && docSnap.data().folderPairs) {
        setFolderPairs(docSnap.data().folderPairs);
      }
    } catch (error) {
      console.error('Failed to fetch current folder pairs', error);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteRule = async (pair, rule) => {
    try {
      const updatedRules = pair.extractionFolder.downloadRules.filter(r => r !== rule);
      const updatedPair = { ...pair, extractionFolder: { ...pair.extractionFolder, downloadRules: updatedRules } };

      const settingsRef = doc(db, 'settings', officeUser.username);
      await updateDoc(settingsRef, {
        folderPairs: folderPairs.map(p => (p === pair ? updatedPair : p)),
      });

      setFolderPairs(folderPairs.map(p => (p === pair ? updatedPair : p)));
    } catch (error) {
      console.error('Failed to delete rule', error);
    }
  };

  const handleDeletePair = async (pair) => {
    try {
      const settingsRef = doc(db, 'settings', officeUser.username);
      await updateDoc(settingsRef, {
        folderPairs: arrayRemove(pair),
      });
      setFolderPairs(folderPairs.filter(p => p !== pair));
    } catch (error) {
      console.error('Failed to delete folder pair', error);
    }
  };

  const openWarningDialog = (pair, ruleIndex) => {
    setPendingRuleIndex(ruleIndex);
    setPendingPair(pair);
    setWarningDialogOpen(true);
  };

  const handleProceedToEdit = () => {
    setWarningDialogOpen(false);
    setEditMode((prev) => ({ ...prev, [pendingRuleIndex]: true }));
    setUpdatedRules((prev) => ({
      ...prev,
      [pendingRuleIndex]: { ...pendingPair.extractionFolder.downloadRules[pendingRuleIndex] },
    }));
  };

  const handleSaveRule = async (pair, ruleIndex) => {
    const updatedRule = updatedRules[ruleIndex];
    const updatedRulesArray = [...pair.extractionFolder.downloadRules];
    updatedRulesArray[ruleIndex] = updatedRule;

    const updatedPair = { ...pair, extractionFolder: { ...pair.extractionFolder, downloadRules: updatedRulesArray } };

    const settingsRef = doc(db, 'settings', officeUser.username);
    try {
      await updateDoc(settingsRef, {
        folderPairs: folderPairs.map(p => (p === pair ? updatedPair : p)),
      });
      setFolderPairs(folderPairs.map(p => (p === pair ? updatedPair : p)));
      setEditMode((prev) => ({ ...prev, [ruleIndex]: false }));
    } catch (error) {
      console.error('Failed to update rule', error);
    }
  };

  const handleRuleChange = (ruleIndex, field, value) => {
    setUpdatedRules((prev) => ({
      ...prev,
      [ruleIndex]: {
        ...prev[ruleIndex],
        [field]: value,
      },
    }));
  };

  const handleEditPair = (pair) => {
    setSelectedPair(pair);
    setEditDialogOpen(true);
  };

  const handleEditDialogClose = () => {
    setEditDialogOpen(false);
    setSelectedPair(null);
    fetchCurrentFolders(); // Refresh the folder pairs
  };

  return (
    <div>
      <Typography variant="h6" gutterBottom>Extraction Rules</Typography>
      {loading ? <CircularProgress /> : (
        <List>
          {folderPairs.map((pair, index) => (
            <React.Fragment key={index}>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`panel${index}-content`}
                  id={`panel${index}-header`}
                >
                  <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                    Mail Folder: {pair.extractionFolder?.fullPath || 'Unknown'}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box mb={2}>
                    <Typography variant="body2" color="textSecondary">
                      <strong>Destination Folder:</strong> {pair.destinationFolder?.fullPath || 'Unknown'}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" mt={1}>
                      <strong>SharePoint Folder:</strong> {pair.extractionFolder?.selectedSharePointFolder?.path || 'Unknown'}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" mt={1}>
                      <strong>File Extensions:</strong> {(pair.extractionFolder?.extensions || []).join(', ') || 'None'}
                    </Typography>
                  </Box>
                  <Divider light />
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={`panel${index}-rules`}
                      id={`panel${index}-rules-header`}
                    >
                      <Typography variant="subtitle2" style={{ fontWeight: 'bold' }}>
                        Download Rules
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <List>
                        {(pair.extractionFolder?.downloadRules || []).map((rule, ruleIndex) => (
                          <Accordion key={ruleIndex}>
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls={`rule${ruleIndex}-content`}
                              id={`rule${ruleIndex}-header`}
                            >
                              <Typography variant="body2">
                                Rule {ruleIndex + 1}: {rule.senderDomain || 'Unknown'}
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              {editMode[ruleIndex] ? (
                                <>
                                  <TextField
                                    label="Sender Domain"
                                    value={updatedRules[ruleIndex]?.senderDomain || ''}
                                    onChange={(e) => handleRuleChange(ruleIndex, 'senderDomain', e.target.value)}
                                    fullWidth
                                  />
                                  <TextField
                                    label="Link Pattern"
                                    value={updatedRules[ruleIndex]?.linkPattern || ''}
                                    onChange={(e) => handleRuleChange(ruleIndex, 'linkPattern', e.target.value)}
                                    fullWidth
                                    multiline
                                    maxRows={4}
                                    variant="outlined"
                                  />
                                  <TextField
                                    label="Download URL Template"
                                    value={updatedRules[ruleIndex]?.downloadUrlTemplate || ''}
                                    onChange={(e) => handleRuleChange(ruleIndex, 'downloadUrlTemplate', e.target.value)}
                                    fullWidth
                                  />
                                  <IconButton edge="end" aria-label="save" onClick={() => handleSaveRule(pair, ruleIndex)}>
                                    <SaveIcon />
                                  </IconButton>
                                </>
                              ) : (
                                <>
                                  <ListItemText
                                    primary={
                                      <>
                                        <div>
                                          <strong>Sender Domain:</strong> {rule.senderDomain || 'Unknown'}
                                        </div>
                                        <div>
                                          <strong>Link Pattern:</strong> {rule.linkPattern || 'Unknown'}
                                        </div>
                                        <div>
                                          <strong>Download URL Template:</strong> {rule.downloadUrlTemplate || 'Unknown'}
                                        </div>
                                      </>
                                    }
                                  />
                                  <IconButton
                                    edge="end"
                                    aria-label="edit"
                                    onClick={() => openWarningDialog(pair, ruleIndex)}
                                  >
                                    <EditIcon />
                                  </IconButton>
                                  <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteRule(pair, rule)}>
                                    <DeleteIcon />
                                  </IconButton>
                                </>
                              )}
                            </AccordionDetails>
                          </Accordion>
                        ))}
                      </List>
                    </AccordionDetails>
                  </Accordion>
                </AccordionDetails>
                <ListItem secondaryAction={
                  <>
                    <IconButton edge="end" aria-label="edit" onClick={() => handleEditPair(pair)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton edge="end" aria-label="delete" onClick={() => handleDeletePair(pair)}>
                      <DeleteIcon />
                    </IconButton>
                  </>
                }>
                </ListItem>
              </Accordion>
              {index < folderPairs.length - 1 && <Divider />}
            </React.Fragment>
          ))}
        </List>
      )}

      {/* Warning dialog before editing */}
      <Dialog
        open={warningDialogOpen}
        onClose={() => setWarningDialogOpen(false)}
        aria-labelledby="warning-dialog-title"
        aria-describedby="warning-dialog-description"
      >
        <DialogTitle id="warning-dialog-title">Warning</DialogTitle>
        <DialogContent>
          <DialogContentText id="warning-dialog-description">
            Making changes to this rule can cause it to stop working if incorrect data is entered. Please ensure the
            information is accurate before proceeding.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setWarningDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleProceedToEdit} color="primary" autoFocus>
            I Understand, Continue
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog for editing a folder pair using the ExtractionSetupStepper */}
      <Dialog open={editDialogOpen} onClose={handleEditDialogClose} fullWidth maxWidth="lg">
        {selectedPair && (
          <ExtractionSetupStepper
            userId={officeUser.username}
            accessToken={accessToken}
            onClose={handleEditDialogClose}
            existingValues={selectedPair}
          />
        )}
      </Dialog>
    </div>
  );
};

export default FolderList;
