import React, { useState, useEffect } from "react";
import { useAuth } from "./AuthContext";
import { getFunctions } from "firebase/functions";
import FolderList from "./FolderList";
import SharePointFolderList from "./SharePointFolderList";
import FileExtensionPickerHome from "./FileExtensionPickerHome";
import DownloadRulePicker from "./DownloadRulePicker";
import ExtractionSetupStepper from "./ExtractionSetupStepper";
import PredefinedRulesManager from "./PreDefinedRulesManager";
import DownloadLogs from "./DownloadLogs";
import LogsDialog from "./LogsDialog";
import DeviceCodePrompt from "./DeviceCodePrompt";
import ServerControl from "./ServerControl";
import CloseIcon from "@mui/icons-material/Close";
import { db } from "./firebase";
import {
  doc,
  getDoc,
  updateDoc,
  onSnapshot,
  deleteDoc,
} from "firebase/firestore";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Box,
  Container,
  Grid,
  Paper,
  Dialog,
  Chip,
  Modal,
  IconButton,
  Popover,
} from "@mui/material";
import axios from "axios";
import "./App.css";
import logo from "./MailExMaxLogoSm.png";

const allowedUsers = ["DRUpGfndYZQ60pzjC2CbulmEDk23"];

const functions = getFunctions();

function Home() {
  const {
    currentUser,
    logout,
    officeUser,
    officeLogin,
    officeLogout,
    accessToken,
    refreshAccessToken,
    username,
  } = useAuth();
  const [logsDialogOpen, setLogsDialogOpen] = useState(false);
  const [selectedFolders, setSelectedFolders] = useState([]);
  const [selectedFolderId, setSelectedFolderId] = useState("");
  const [destinationFolderId, setDestinationFolderId] = useState("");
  const [selectedComponent, setSelectedComponent] = useState(null);
  const [stepperDialogOpen, setStepperDialogOpen] = useState(false);
  const [deviceCodeDialogOpen, setDeviceCodeDialogOpen] = useState(false);
  const [deviceCodeData, setDeviceCodeData] = useState(null);
  const [serverStatus, setServerStatus] = useState("loading");
  const [modalOpen, setModalOpen] = useState(false);
  const [activeButton, setActiveButton] = useState(null);
  const [baseUrl, setBaseUrl] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [subscriptionKey, setSubscriptionKey] = useState("");
  const [logsVisible, setLogsVisible] = useState(false);

  useEffect(() => {
    const fetchBaseUrl = async () => {
      const serverDocRef = doc(db, "config", "server");
      try {
        const docSnap = await getDoc(serverDocRef);
        if (docSnap.exists()) {
          setBaseUrl(docSnap.data().baseUrl);
        } else {
          console.error("No such document!");
        }
      } catch (error) {
        console.error("Error fetching base URL:", error);
      }
    };

    const fetchSubscriptionKey = async () => {
      const apiConfigDocRef = doc(db, "config", "keys");
      try {
        const docSnap = await getDoc(apiConfigDocRef);
        if (docSnap.exists()) {
          setSubscriptionKey(docSnap.data().subscriptionKey);
        } else {
          console.error("No API subscription key document found!");
        }
      } catch (error) {
        console.error("Error fetching API subscription key:", error);
      }
    };

    fetchBaseUrl();
    fetchSubscriptionKey();
  }, []);

  useEffect(() => {
    if (officeUser) {
      fetchCurrentFolders();
    }
  }, [officeUser]);

  useEffect(() => {
    const checkAccessToken = async () => {
      try {
        await refreshAccessToken();
        console.log("Access token refreshed");
      } catch (error) {
        console.error("Failed to refresh access token:", error);
        officeLogin();
      }
    };

    const interval = setInterval(checkAccessToken, 60000);
    return () => clearInterval(interval);
  }, [officeUser, refreshAccessToken, officeLogin]);

  useEffect(() => {
    const deviceCodeRef = doc(db, "deviceCodes", "current");
    const unsubscribe = onSnapshot(deviceCodeRef, (docSnapshot) => {
      if (docSnapshot.exists()) {
        setDeviceCodeData(docSnapshot.data());
      } else {
        setDeviceCodeData(null);
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    fetchServerStatus();
  }, [baseUrl, subscriptionKey]);

  const fetchServerStatus = async () => {
    if (!baseUrl || !subscriptionKey) return;
    try {
      const response = await axios.get(`${baseUrl}/status`, {
        headers: {
          "Ocp-Apim-Subscription-Key": subscriptionKey,
        },
      });
      setServerStatus(response.data.status);
    } catch (error) {
      console.error("Error fetching server status:", error);
      setServerStatus("error");
    }
  };

  const handleChipClick = () => {
    if (deviceCodeData) {
      handleOpenDeviceCodeDialog();
    } else {
      setModalOpen(true);
    }
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleCloseDeviceCodeDialog = () => {
    setDeviceCodeDialogOpen(false);
  };

  const fetchCurrentFolders = async () => {
    if (officeUser && officeUser.username) {
      const settingsRef = doc(db, "settings", officeUser.username);
      try {
        const docSnap = await getDoc(settingsRef);
        if (docSnap.exists()) {
          setSelectedFolders(docSnap.data().selectedMailboxFolders || []);
        } else {
          console.log("No document found at the specified path.");
        }
      } catch (error) {
        console.error("Failed to fetch current selected folders:", error);
      }
    } else {
      console.log("Office user or username is undefined, unable to fetch folders.");
    }
  };

  const handleComponentSelection = (component, button) => {
    setSelectedComponent(component);
    setActiveButton(button);
  };

  const handleLogsVisibilityChange = (visible) => {
    setLogsVisible(visible);
  };

  const handleOpenLogsDialog = () => {
    if (allowedUsers.includes(currentUser.uid)) {
      setLogsDialogOpen(true);
    } else {
      alert("Access Denied: You do not have permission to view Logs.");
    }
  };

  const handleCloseLogsDialog = () => {
    setLogsDialogOpen(false);
  };

  const handleExtensionsChange = (updatedFolders) => {
    setSelectedFolders(updatedFolders);
  };

  const handleSaveFolders = async () => {
    const settingsRef = doc(db, "settings", officeUser.username);
    try {
      await updateDoc(settingsRef, {
        selectedMailboxFolders: selectedFolders,
      });
      const docSnap = await getDoc(settingsRef);
      if (docSnap.exists()) {
        console.log("Verified Firestore data:", docSnap.data().selectedMailboxFolders);
      } else {
        console.log("No such document in Firestore.");
      }
    } catch (error) {
      console.error("Failed to save selected folders", error);
    }
  };

  const handleOpenStepperDialog = () => {
    if (allowedUsers.includes(currentUser.uid)) {
      setStepperDialogOpen(true);
    } else {
      alert("Access Denied: You do not have permission to perform this action.");
    }
  };

  const handleCloseStepperDialog = () => {
    setStepperDialogOpen(false);
  };

  const handleOpenDeviceCodeDialog = () => {
    setDeviceCodeDialogOpen(true);
  };

  const getStatusColor = (status) => {
    if (deviceCodeData) {
      return "error";
    }
    switch (status) {
      case "online":
        return "success";
      case "offline":
        return "error";
      case "loading":
        return "warning";
      case "error":
        return "error";
      default:
        return "default";
    }
  };

  const getChipLabel = () => {
    if (deviceCodeData) {
      return "Login Required";
    }
    return serverStatus;
  };

  const handleOpenConfirm = (event) => {
    if (allowedUsers.includes(currentUser.uid)) {
      setAnchorEl(event.currentTarget);
      setConfirmOpen(true);
    } else {
      alert("Access Denied: You do not have permission to remove the account.");
    }
  };

  const handleCloseConfirm = () => {
    setAnchorEl(null);
    setConfirmOpen(false);
  };

  const handleDeleteAccount = async () => {
    try {
      const userDocRef = doc(db, "settings", officeUser.username);
      await deleteDoc(userDocRef);
      alert("Account deleted successfully.");
      logout();
      handleCloseConfirm();
    } catch (error) {
      console.error("Error deleting account:", error);
      alert("Failed to delete the account. Please try again.");
    }
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" className="App-header">
        <Toolbar>
          <img src={logo} alt="MailExMax Logo" style={{ height: 40, marginRight: 16 }} />
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            MailExMax
          </Typography>

          <Button color="inherit" onClick={logout}>
            Log Out of MailExMax
          </Button>
          <Box
            component="fieldset"
            sx={{
              border: "1px solid #ccc",
              borderRadius: "4px",
              padding: "4px 8px",
              position: "relative",
              zIndex: 1301,
              marginLeft: "auto",
            }}
          >
            <Typography
              component="legend"
              variant="subtitle2"
              sx={{ padding: "0 8px", color: "#FFFFFF" }}
            >
              Extractor Status
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              <Chip
                label={
                  <Box display="flex" justifyContent="center">
                    <Typography variant="body2" sx={{ color: "inherit", textTransform: "uppercase" }}>
                      {getChipLabel()}
                    </Typography>
                  </Box>
                }
                color={getStatusColor(serverStatus)}
                onClick={handleChipClick}
                sx={{ cursor: "pointer", width: 120, height: 40 }}
              />
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <Toolbar>
        <Typography variant="h4" gutterBottom>
          Welcome, {currentUser.email}
        </Typography>
      </Toolbar>
      <Modal
        open={modalOpen}
        onClose={handleCloseModal}
        aria-labelledby="server-control-modal-title"
        aria-describedby="server-control-modal-description"
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <Paper sx={{ position: "relative", p: 2, maxWidth: 500, width: "90%" }}>
          <IconButton
            aria-label="close"
            onClick={handleCloseModal}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              overflow: "auto",
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography id="server-control-modal-title" variant="h6" component="h2">
            Extractor Service
          </Typography>
          <ServerControl
            baseUrl={baseUrl}
            username={username}
            allowed={allowedUsers.includes(currentUser.uid)}
            onLogsVisibilityChange={handleLogsVisibilityChange}
          />
        </Paper>
      </Modal>
      <Dialog
        open={deviceCodeDialogOpen}
        onClose={handleCloseDeviceCodeDialog}
        fullWidth
        maxWidth="sm"
      >
        <DeviceCodePrompt
          open={deviceCodeDialogOpen}
          onClose={handleCloseDeviceCodeDialog}
          username={username}
        />
      </Dialog>
      <Container>
        <Box sx={{ my: 4 }}>
          <Dialog
            open={stepperDialogOpen}
            onClose={handleCloseStepperDialog}
            fullWidth
            maxWidth="lg"
          >
            <ExtractionSetupStepper
              accessToken={accessToken}
              userId={officeUser && officeUser.username}
              onClose={handleCloseStepperDialog}
            />
          </Dialog>
          {!officeUser && (
            <>
              <Typography variant="h6" gutterBottom>
                {username
                  ? `Log In to Microsoft as ${username}`
                  : "Log In to Microsoft"}
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={officeLogin}
                className="button"
              >
                Login
              </Button>
            </>
          )}
          {officeUser && officeUser.username && (
            <Box
              my={4}
              p={2}
              component={Paper}
              elevation={3}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="h6" gutterBottom>
                Extractor Mail Account: {username}
              </Typography>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleOpenConfirm}
                className="button"
              >
                Remove Account
              </Button>
              <Popover
                open={confirmOpen}
                anchorEl={anchorEl}
                onClose={handleCloseConfirm}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                <Box p={2}>
                  <Typography>
                    Are you sure you want to remove your mail account {username}
                    ?
                  </Typography>
                  <Box mt={2} display="flex" justifyContent="space-between">
                    <Button
                      variant="contained"
                      onClick={handleCloseConfirm}
                      className="button"
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleDeleteAccount}
                      className="button"
                    >
                      Yes, Remove Account
                    </Button>
                  </Box>
                </Box>
              </Popover>
            </Box>
          )}

          {officeUser && selectedFolders.length > 0 && (
            <>
              <Typography variant="h6" gutterBottom>
                From Mailfolders:
              </Typography>
              <ul>
                {selectedFolders.map((folder) => (
                  <li key={folder.id}>
                    {folder.mailboxName}\{folder.displayName}
                  </li>
                ))}
              </ul>
            </>
          )}
          {officeUser && (
            <>
              <Grid container spacing={2}>
                <Grid item>
                  <Button
                    variant="contained"
                    className="button"
                    onClick={handleOpenStepperDialog}
                  >
                    New Extraction Rule
                  </Button>
                </Grid>

                <Grid item>
                  <Button
                    variant="contained"
                    className={
                      activeButton === "FolderList" ? "button active" : "button"
                    }
                    onClick={() =>
                      allowedUsers.includes(currentUser.uid)
                        ? handleComponentSelection("FolderList", "FolderList")
                        : alert(
                            "Access Denied: You do not have permission to view Extraction Rules."
                          )
                    }
                  >
                    View Extraction Rules
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    className={
                      activeButton === "DownloadRulePicker"
                        ? "button active"
                        : "button"
                    }
                    onClick={() =>
                      allowedUsers.includes(currentUser.uid)
                        ? handleComponentSelection(
                            "DownloadRulePicker",
                            "DownloadRulePicker"
                          )
                        : alert(
                            "Access Denied: You do not have permission to view Download Rules."
                          )
                    }
                  >
                    Download Rules
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    className={
                      activeButton === "PreDefinedRulesManager"
                        ? "button active"
                        : "button"
                    }
                    onClick={() =>
                      allowedUsers.includes(currentUser.uid)
                        ? handleComponentSelection(
                            "PreDefinedRulesManager",
                            "PreDefinedRulesManager"
                          )
                        : alert(
                            "Access Denied: You do not have permission to view PreDefined Rules Manager."
                          )
                    }
                  >
                    PreDefined Rules Manager
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    className={
                      activeButton === "LogsDialog" ? "button active" : "button"
                    }
                    onClick={handleOpenLogsDialog}
                  >
                    View Logs
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    className={
                      activeButton === "PDownloadLogs"
                        ? "button active"
                        : "button"
                    }
                    onClick={() =>
                      allowedUsers.includes(currentUser.uid)
                        ? handleComponentSelection("DownloadLogs", "DownloadLogs")
                        : alert(
                            "Access Denied: You do not have permission to download logs."
                          )
                    }
                  >
                    Download Logs
                  </Button>
                </Grid>
              </Grid>

              <Box sx={{ mt: 4 }}>
                <Paper elevation={3} className="card">
                  {selectedComponent === "FolderList" && (
                    <FolderList
                      accessToken={accessToken}
                      userId={officeUser?.username}
                    />
                  )}
                  {selectedComponent === "SharePointFolderList" && (
                    <SharePointFolderList
                      accessToken={accessToken}
                      userId={officeUser?.username}
                    />
                  )}
                  {selectedComponent === "FileExtensionPickerHome" && (
                    <FileExtensionPickerHome
                      folders={selectedFolders}
                      userId={officeUser?.username}
                    />
                  )}
                  {selectedComponent === "DownloadRulePicker" && (
                    <DownloadRulePicker
                      folders={selectedFolders}
                      userId={officeUser?.username}
                    />
                  )}
                  {selectedComponent === "PreDefinedRulesManager" && (
                    <PredefinedRulesManager />
                  )}
                  {selectedComponent === "ServerControl" && (
                    <ServerControl
                    baseUrl={baseUrl}
                    username={username}
                    allowed={allowedUsers.includes(currentUser.uid)}
                    onLogsVisibilityChange={handleLogsVisibilityChange} // Add this line
                  />
                
                  )}
                  {selectedComponent === "DownloadLogs" && <DownloadLogs />}
                </Paper>
              </Box>
            </>
          )}
        </Box>
        {logsDialogOpen && (
          <LogsDialog
            open={logsDialogOpen}
            onClose={handleCloseLogsDialog}
            selectedFolderId={selectedFolderId}
            destinationFolderId={destinationFolderId}
          />
        )}
      </Container>
    </Box>
  );
}

export default Home;
