import React, { useRef, useState } from 'react';
import { useAuth } from './AuthContext';
import { useNavigate } from 'react-router-dom';
import {
  Container, TextField, Button, Typography, Box, Dialog, DialogActions, DialogContent, DialogTitle, Tabs, Tab, Alert
} from '@mui/material';
import './App.css';

function Login() {
  const emailRef = useRef();
  const passwordRef = useRef();
  const { login } = useAuth();
  const navigate = useNavigate();
  const [tabValue, setTabValue] = useState(0);
  const [loginDialogOpen, setLoginDialogOpen] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const handleFirebaseLogin = async (e) => {
    e.preventDefault();
    try {
      await login(emailRef.current.value, passwordRef.current.value);
      navigate('/');
    } catch {
      setError("Failed to login");
    }
  };

  const openLoginDialog = () => {
    setLoginDialogOpen(true);
  };

  const closeLoginDialog = () => {
    setLoginDialogOpen(false);
    setError('');
    setSuccess('');
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    setError('');
    setSuccess('');
  };

  return (
    <Box sx={{
      position: 'relative',
      height: '100vh',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}>
      <Box sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
        zIndex: 1,
      }} />
      <Container sx={{ position: 'relative', zIndex: 2, color: 'white' }}>
        <Box
          sx={{
            textAlign: 'center',
            mb: 4,
          }}
        >
          <Typography
            variant="h2"
            component="h1"
          >
            Welcome to MailExMax
          </Typography>
          <Typography variant="h5" gutterBottom>
            Sign in with your username and password to continue
          </Typography>
          <Button variant="contained" className="button" onClick={openLoginDialog} sx={{ mt: 2 }}>
            Login
          </Button>
        </Box>
        <Dialog open={loginDialogOpen} onClose={closeLoginDialog} className="modal">
          <DialogTitle>Login</DialogTitle>
          <DialogContent>
            <Tabs value={tabValue} onChange={handleTabChange} centered>
              <Tab label="Login" />
            </Tabs>
            {tabValue === 0 && (
              <Box component="form" onSubmit={handleFirebaseLogin}>
                {error && <Alert severity="error">{error}</Alert>}
                <TextField
                  label="Email"
                  type="email"
                  inputRef={emailRef}
                  fullWidth
                  required
                  margin="normal"
                  className="text-field"
                />
                <TextField
                  label="Password"
                  type="password"
                  inputRef={passwordRef}
                  fullWidth
                  required
                  margin="normal"
                  className="text-field"
                />
                <Button type="submit" variant="contained" className="button" fullWidth>
                  Login
                </Button>
              </Box>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={closeLoginDialog} className="button" color="secondary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </Box>
  );
}

export default Login;
