import React, { useState } from 'react';
import { Box, Button, Typography, MenuItem, Select, FormControl, InputLabel } from '@mui/material';

const PredefinedRuleSelector = ({ predefinedRules, selectedPredefinedRule, setSelectedPredefinedRule, onAddRule }) => {
    const [selectedRuleDetails, setSelectedRuleDetails] = useState(null);

    const handlePredefinedRuleChange = (event) => {
        const selectedRuleId = event.target.value;
        const selectedRule = predefinedRules.find(rule => rule.id === selectedRuleId);

        if (selectedRule) {
            setSelectedPredefinedRule(selectedRuleId);
            setSelectedRuleDetails({
                senderDomain: selectedRule.senderDomain,
                downloadUrlTemplate: selectedRule.downloadUrlTemplate || '',
                actions: Array.isArray(selectedRule.actions) ? selectedRule.actions : [],
                linkPattern: selectedRule.linkPattern || '',
            });
        } else {
            setSelectedRuleDetails(null);
        }
    };

    const handleAddRule = () => {
        if (selectedRuleDetails) {
            onAddRule({
                senderDomain: selectedRuleDetails.senderDomain,
                downloadUrlTemplate: selectedRuleDetails.downloadUrlTemplate, // Use the download URL template
                actions: selectedRuleDetails.actions,
                linkPattern: selectedRuleDetails.linkPattern,
            });
        }
    };

    return (
        <Box mt={2}>
            <FormControl fullWidth>
                <InputLabel>Select Predefined Rule</InputLabel>
                <Select
                    value={selectedPredefinedRule || ""}
                    onChange={handlePredefinedRuleChange}
                >
                    {predefinedRules.map((rule) => (
                        <MenuItem key={rule.id} value={rule.id}>
                            {rule.senderDomain}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            {selectedRuleDetails && (
                <Box mt={2}>
                    <Typography variant="body1">
                        <strong>Sender Domain:</strong> {selectedRuleDetails.senderDomain}
                    </Typography>
                    <Typography variant="body1">
                        <strong>Download URL Template:</strong> {selectedRuleDetails.downloadUrlTemplate}
                    </Typography>
                    <Typography variant="body1">
                        <strong>Link Pattern:</strong> {selectedRuleDetails.linkPattern}
                    </Typography>
                    <Typography variant="body1">
                        <strong>Actions:</strong> 
                        {selectedRuleDetails.actions.length > 0 ? (
                            <ul>
                                {selectedRuleDetails.actions.map((action, index) => (
                                    <li key={index}>
                                        <strong>Selector:</strong> {action.selector},{" "}
                                        <strong>Type:</strong> {action.type}
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            "No actions defined"
                        )}
                    </Typography>
                </Box>
            )}

            <Button
                onClick={handleAddRule}
                variant="contained"
                color="primary"
                className="button"
                disabled={!selectedRuleDetails}
                mt={2}
            >
                Add Rule
            </Button>
        </Box>
    );
};

export default PredefinedRuleSelector;
