import React, { useState, useEffect } from 'react';
import { db } from './firebase';
import { doc, onSnapshot } from 'firebase/firestore';
import {
    Typography,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Tooltip,
    DialogContentText
} from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

const DeviceCodePrompt = ({ open, onClose, username }) => {
    const [deviceCodeData, setDeviceCodeData] = useState(null);
    const [remainingTime, setRemainingTime] = useState(null);

    useEffect(() => {
        let interval;

        if (open) {
            const deviceCodeRef = doc(db, 'deviceCodes', 'current');
            const unsubscribe = onSnapshot(deviceCodeRef, (docSnapshot) => {
                if (docSnapshot.exists()) {
                    const data = docSnapshot.data();
                    setDeviceCodeData(data);
                    const expiryTime = new Date(data.timestamp.toDate().getTime() + data.expiresIn * 1000);
                    setRemainingTime(Math.max(0, (expiryTime - new Date()) / 1000));

                    interval = setInterval(() => {
                        const remaining = Math.max(0, (expiryTime - new Date()) / 1000);
                        setRemainingTime(remaining);
                        if (remaining === 0) {
                            clearInterval(interval);
                        }
                    }, 1000);
                } else {
                    setDeviceCodeData(null);
                    setRemainingTime(null);
                    if (interval) {
                        clearInterval(interval);
                    }
                }
            });

            return () => {
                unsubscribe();
                if (interval) {
                    clearInterval(interval);
                }
            };
        }
    }, [open]);

    const handleCopyCode = () => {
        navigator.clipboard.writeText(deviceCodeData.deviceCode);
    };

    const handleNavigateAndPaste = () => {
        handleCopyCode();
        window.open(deviceCodeData.authLink, '_blank');
    };

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
            <DialogTitle>Mail Account Login Required for {username}</DialogTitle>
            
            <DialogContent>
                <DialogContentText mb={2}>
                    Click the Copy Code And Open Link Button or manually copy the code below, then navigate to the listed URL to sign in with the credentials for {username}.
                </DialogContentText>
                
                {deviceCodeData ? (
                    <Box>
                        <Box display="flex" alignItems="center" mb={2}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleNavigateAndPaste}
                                endIcon={<OpenInNewIcon />}
                            >
                                Copy Code and Open Link
                            </Button>
                        </Box>
                        
                        <Box display="flex" alignItems="center" mb={2}>
                            <Typography variant="h6" gutterBottom>
                                Code: {deviceCodeData.deviceCode}
                            </Typography>
                            <Tooltip title="Copy Code" arrow>
                                <IconButton onClick={handleCopyCode} size="small" sx={{ ml: 1 }}>
                                    <ContentCopyIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        </Box>
                        
                        <Typography variant="body2" color="textSecondary" mb={2}>
                            This code will expire in {Math.floor(remainingTime)} seconds.
                        </Typography>
                        
                        <Typography variant="body1">
                            To authenticate, visit{' '}
                            <a 
                                href={deviceCodeData.authLink} 
                                target="_blank" 
                                rel="noopener noreferrer" 
                                style={{ color: '#1a73e8', textDecoration: 'underline' }}
                            >
                                {deviceCodeData.authLink}
                            </a>
                        </Typography>
                    </Box>
                ) : (
                    <Typography variant="body1">No device code available at the moment.</Typography>
                )}
            </DialogContent>
            
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DeviceCodePrompt;
