import React, { useContext, useState, useEffect, createContext } from 'react';
import { signInWithEmailAndPassword, createUserWithEmailAndPassword, signOut, onAuthStateChanged } from 'firebase/auth';
import { auth, db } from './firebase';
import { useMsal } from '@azure/msal-react';
import { collection, getDocs, doc, setDoc } from 'firebase/firestore';

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const { instance } = useMsal();
  const [officeUser, setOfficeUser] = useState(JSON.parse(localStorage.getItem('officeUser')));
  const [accessToken, setAccessToken] = useState(localStorage.getItem('accessToken'));
  const [username, setUsername] = useState('');

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setCurrentUser(user);
      if (user) {
        await checkFirestoreForSettings();
        await refreshAccessToken();
      }
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  useEffect(() => {
    if (officeUser) {
      localStorage.setItem('officeUser', JSON.stringify(officeUser));
    } else {
      localStorage.removeItem('officeUser');
    }
  }, [officeUser]);

  useEffect(() => {
    if (accessToken) {
      localStorage.setItem('accessToken', accessToken);
    } else {
      localStorage.removeItem('accessToken');
    }
  }, [accessToken]);

  const checkFirestoreForSettings = async () => {
    const settingsRef = collection(db, 'settings');
    try {
      const querySnapshot = await getDocs(settingsRef);
      if (!querySnapshot.empty) {
        querySnapshot.forEach(doc => {
          console.log('Found Firestore document:', doc.id);
          setUsername(doc.id);
        });
      } else {
        console.log('No documents found in Firestore settings collection.');
      }
    } catch (error) {
      console.error('Failed to fetch documents from Firestore', error);
    }
  };

  const login = (email, password) => {
    return signInWithEmailAndPassword(auth, email, password);
  };

  const signup = (email, password) => {
    return createUserWithEmailAndPassword(auth, email, password);
  };

  const logout = () => {
    setOfficeUser(null);
    setAccessToken(null);
    return signOut(auth);
  };

  const officeLogin = async () => {
    try {
      console.log('Attempting Office 365 login with username:', username);
      const loginResponse = await instance.loginPopup({
        scopes: ["User.ReadBasic.All", "Mail.ReadWrite", "Mail.ReadWrite.Shared", "MailboxSettings.Read", "Sites.Read.All", "Files.ReadWrite.All"],
        loginHint: username || undefined
      });
      setOfficeUser(loginResponse.account);
  
      const tokenResponse = await instance.acquireTokenSilent({
        scopes: ["User.ReadBasic.All", "Mail.ReadWrite", "Mail.ReadWrite.Shared", "MailboxSettings.Read", "Sites.Read.All", "Files.ReadWrite.All"],
        account: loginResponse.account
      });
      setAccessToken(tokenResponse.accessToken);
  
      // If username isn't set, save it to the database
      const microsoftUsername = loginResponse.account.username;
      if (!username) {
        const newUserData = {
          accessToken: tokenResponse.accessToken || '',
          batchSize: 50, // default value
          destinationMailFolder: {},
          displayName: currentUser.displayName || '',
          id: currentUser.uid,
          path: '',
          frequency: 'daily',
          selectedMailboxFolders: [],
          selectedSharePointFolder: {},
          subscriptionIds: []
        };
        await saveUsernameToFirestore(microsoftUsername, newUserData);
        setUsername(microsoftUsername);
      }
    } catch (error) {
      console.error("Office login error:", error);
    }
  };
  

  const saveUsernameToFirestore = async (username, data) => {
    const settingsRef = doc(db, 'settings', username);
    try {
      await setDoc(settingsRef, data);
      console.log('New user data saved to Firestore');
    } catch (error) {
      console.error('Failed to save new user data to Firestore', error);
    }
  };

  const refreshAccessToken = async () => {
    if (!officeUser) return;
    try {
      const tokenResponse = await instance.acquireTokenSilent({
        scopes: ["User.ReadBasic.All", "Mail.ReadWrite", "Mail.ReadWrite.Shared", "MailboxSettings.Read", "Sites.Read.All", "Files.ReadWrite.All"],
        account: officeUser
      });
      setAccessToken(tokenResponse.accessToken);
      return tokenResponse.accessToken;
    } catch (error) {
      console.error("Failed to refresh access token:", error);
      // Handle token refresh failure (e.g., prompt login again)
    }
  };

  const officeLogout = () => {
    instance.logoutPopup();
    setOfficeUser(null);
    setAccessToken(null);
  };

  const value = {
    currentUser,
    login,
    signup,
    logout,
    officeUser,
    officeLogin,
    officeLogout,
    accessToken,
    refreshAccessToken,
    username
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
