import React, { useState, useEffect } from 'react';
import { Button, Checkbox, FormControlLabel, TextField, Typography, Grid, MenuItem, Select, FormControl, InputLabel, Radio, RadioGroup } from '@mui/material';
import { db } from './firebase'; // Import Firestore
import { doc, updateDoc, getDoc } from 'firebase/firestore';

const commonExtensions = {
    Documents: ['pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx'],
    Images: ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'svg'],
    Videos: ['mp4', 'avi', 'mkv', 'mov'],
    Archives: ['zip', 'rar', 'tar', 'gz'],
};

const FileExtensionPickerHome = ({ userId }) => {
    const [folders, setFolders] = useState([]);
    const [selectedFolder, setSelectedFolder] = useState('');
    const [folderExtensions, setFolderExtensions] = useState({});
    const [customExtension, setCustomExtension] = useState('');
    const [convertToPdf, setConvertToPdf] = useState('no'); // State for convert to PDF option

    useEffect(() => {
        const fetchFolders = async () => {
            const settingsRef = doc(db, 'settings', userId);
            const docSnap = await getDoc(settingsRef);
            if (docSnap.exists()) {
                const folderPairs = docSnap.data().folderPairs || [];
                const extractionFolders = folderPairs.map(pair => pair.extractionFolder);
                setFolders(extractionFolders);

                const initialExtensions = {};
                extractionFolders.forEach(folder => {
                    initialExtensions[folder.id] = folder.extensions || [];
                });
                setFolderExtensions(initialExtensions);
                if (extractionFolders.length > 0) {
                    setSelectedFolder(extractionFolders[0].id);
                }
            } else {
                console.log('No such document in Firestore.');
            }
        };

        fetchFolders();
    }, [userId]);

    const handleToggleExtension = (extension) => {
        setFolderExtensions(prev => {
            const updatedExtensions = prev[selectedFolder]?.includes(extension)
                ? prev[selectedFolder].filter(ext => ext !== extension)
                : [...(prev[selectedFolder] || []), extension];
            return { ...prev, [selectedFolder]: updatedExtensions };
        });
    };

    const handleCustomExtension = () => {
        if (customExtension && !folderExtensions[selectedFolder]?.includes(customExtension)) {
            setFolderExtensions(prev => ({
                ...prev,
                [selectedFolder]: [...(prev[selectedFolder] || []), customExtension]
            }));
            setCustomExtension('');
        }
    };

    const handleFolderChange = (event) => {
        const folderId = event.target.value;
        setSelectedFolder(folderId);
    };

    const handleSave = async () => {
        if (!selectedFolder) {
            console.error('No folder selected');
            return;
        }

        console.log('Saving folder extensions for folder:', selectedFolder);
        console.log('Extensions:', folderExtensions[selectedFolder]);

        const updatedFolders = folders.map(folder => {
            if (folder.id === selectedFolder) {
                return { 
                    ...folder, 
                    extensions: folderExtensions[selectedFolder], 
                    convertToPdf: convertToPdf || "no", // Ensure convertToPdf is always defined
                    childFolders: folder.childFolders || [], // Ensure childFolders is always defined
                    isHidden: folder.isHidden || false, // Ensure isHidden is always defined
                    sizeInBytes: folder.sizeInBytes || 0, // Ensure sizeInBytes is always defined
                    totalItemCount: folder.totalItemCount || 0, // Ensure totalItemCount is always defined
                    unreadItemCount: folder.unreadItemCount || 0, // Ensure unreadItemCount is always defined
                    parentFolderId: folder.parentFolderId || "", // Ensure parentFolderId is always defined
                };
            }
            return folder;
        });

        const settingsRef = doc(db, 'settings', userId);
        try {
            console.log('Saving selected folders to Firestore:', updatedFolders);
            await updateDoc(settingsRef, {
                folderPairs: updatedFolders.map(folder => ({
                    extractionFolder: folder,
                    destinationFolder: folders.find(f => f.id === folder.id)?.destinationFolder || {}
                })),
            });
            console.log('Selected folders updated successfully.');
            
            const docSnap = await getDoc(settingsRef);
            if (docSnap.exists()) {
                console.log('Verified Firestore data:', docSnap.data().folderPairs);
            } else {
                console.log('No such document in Firestore.');
            }
        } catch (error) {
            console.error('Failed to save selected folders', error);
        }
    };

    return (
        <div>
            <FormControl fullWidth>
                <InputLabel>Select Folder</InputLabel>
                <Select
                    value={selectedFolder}
                    onChange={handleFolderChange}
                >
                    {folders.map(folder => (
                        <MenuItem key={folder.id} value={folder.id}>
                            {folder.fullPath}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <Typography variant="h6">Select File Extensions for {folders.find(f => f.id === selectedFolder)?.displayName}</Typography>
            {Object.keys(commonExtensions).map((category) => (
                <div key={category}>
                    <Typography variant="subtitle1">{category}</Typography>
                    <Grid container>
                        {commonExtensions[category].map((extension) => (
                            <Grid item xs={4} key={extension}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={folderExtensions[selectedFolder]?.includes(extension) || false}
                                            onChange={() => handleToggleExtension(extension)}
                                        />
                                    }
                                    label={extension}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </div>
            ))}
            <div>
                <TextField
                    label="Custom Extension"
                    value={customExtension}
                    onChange={(e) => setCustomExtension(e.target.value)}
                />
                <Button onClick={handleCustomExtension}>Add</Button>
            </div>
            <div>
                <Typography variant="h6">Convert All Selected, Supported Filetypes to PDF?</Typography>
                <Typography variant="body2">Supported Filetypes: doc, docx, xls, xlsx, ppt, pptx</Typography>
                <RadioGroup
                    value={convertToPdf}
                    onChange={(e) => setConvertToPdf(e.target.value)}
                    row
                >
                    <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                    <FormControlLabel value="no" control={<Radio />} label="No" />
                </RadioGroup>
            </div>
            <Button variant="contained" onClick={handleSave}>Save</Button>
        </div>
    );
};

export default FileExtensionPickerHome;
