import { PublicClientApplication } from '@azure/msal-browser';

const msalConfig = {
  auth: {
    clientId: '7911627b-26d7-4f58-be0d-d9c63442e963',
    authority: 'https://login.microsoftonline.com/common',
    redirectUri: 'https://mailexmax.com/',
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false,
  },
};

const loginRequest = {
  scopes: ['User.ReadBasic.All', 'Mail.Read', 'Mail.ReadWrite']
};

const msalInstance = new PublicClientApplication(msalConfig);

export { msalInstance, loginRequest };
