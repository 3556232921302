import React, { useState, useEffect } from 'react';
import { Button, Checkbox, FormControlLabel, TextField, Typography, Grid, Radio, RadioGroup } from '@mui/material';
import { db } from './firebase'; // Import Firestore
import { doc, updateDoc, getDoc } from 'firebase/firestore';
import './App.css'; // Import the CSS file

const commonExtensions = {
    Documents: ['pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx'],
    Images: ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'svg'],
    Videos: ['mp4', 'avi', 'mkv', 'mov'],
    Archives: ['zip', 'rar', 'tar', 'gz'],
};

const FileExtensionPicker = ({ extractionFolder, userId, onSave }) => {
    const [folderExtensions, setFolderExtensions] = useState([]);
    const [customExtension, setCustomExtension] = useState('');
    const [convertToPdf, setConvertToPdf] = useState(extractionFolder.convertToPdf || 'no'); // State for convert to PDF option

    useEffect(() => {
        if (!extractionFolder) {
            console.error('Invalid extractionFolder');
            return;
        }

        const fetchFolderExtensions = async () => {
            try {
                const settingsRef = doc(db, 'settings', userId);
                const docSnap = await getDoc(settingsRef);

                if (docSnap.exists()) {
                    const folderPairs = docSnap.data().folderPairs || [];
                    const folderPair = folderPairs.find(pair => pair.extractionFolder.id === extractionFolder.id);

                    if (folderPair) {
                        setFolderExtensions(folderPair.extractionFolder.extensions || []);
                    }
                } else {
                    console.log('No such document in Firestore.');
                }
            } catch (error) {
                console.error('Failed to fetch folder extensions', error);
            }
        };

        fetchFolderExtensions();
    }, [extractionFolder, userId]);

    const handleToggleExtension = (extension) => {
        setFolderExtensions(prev => {
            const updatedExtensions = prev.includes(extension)
                ? prev.filter(ext => ext !== extension)
                : [...prev, extension];
            return updatedExtensions;
        });
    };

    const handleCustomExtension = () => {
        if (customExtension && !folderExtensions.includes(customExtension)) {
            setFolderExtensions(prev => [...prev, customExtension]);
            setCustomExtension('');
        }
    };

    const handleSave = async () => {
        if (!extractionFolder) {
            console.error('No folder selected');
            return;
        }

        console.log('Saving folder extensions for folder:', extractionFolder.id);
        console.log('Extensions:', folderExtensions);

        const settingsRef = doc(db, 'settings', userId);
        try {
            const docSnap = await getDoc(settingsRef);
            if (docSnap.exists()) {
                const currentData = docSnap.data();
                const updatedFolderPairs = currentData.folderPairs.map(pair => {
                    if (pair.extractionFolder.id === extractionFolder.id) {
                        return {
                            ...pair,
                            extractionFolder: {
                                ...pair.extractionFolder,
                                extensions: folderExtensions,
                                convertToPdf: convertToPdf
                            }
                        };
                    }
                    return pair;
                });

                await updateDoc(settingsRef, {
                    folderPairs: updatedFolderPairs,
                });
                console.log('Selected folders updated successfully.');

                const newDocSnap = await getDoc(settingsRef);
                if (newDocSnap.exists()) {
                    console.log('Verified Firestore data:', newDocSnap.data().folderPairs);
                    onSave(newDocSnap.data().folderPairs); // Call onSave with the updated folder pairs
                } else {
                    console.log('No such document in Firestore.');
                }
            }
        } catch (error) {
            console.error('Failed to save selected folders', error);
        }
    };

    return (
        <div className="file-extension-picker">
            <Typography variant="h6" className="typography">Select File Extensions for {extractionFolder.fullPath}</Typography>
            {Object.keys(commonExtensions).map((category) => (
                <div key={category}>
                    <Typography variant="subtitle1" className="typography">{category}</Typography>
                    <Grid container className="grid-container">
                        {commonExtensions[category].map((extension) => (
                            <Grid item xs={4} key={extension} className="grid-item">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={folderExtensions.includes(extension)}
                                            onChange={() => handleToggleExtension(extension)}
                                            className="checkbox"
                                        />
                                    }
                                    label={extension}
                                    className="form-control-label"
                                />
                            </Grid>
                        ))}
                    </Grid>
                </div>
            ))}
            <div className="custom-extension">
                <TextField
                    label="Custom Extension"
                    value={customExtension}
                    onChange={(e) => setCustomExtension(e.target.value)}
                    className="text-field"
                />
                <Button onClick={handleCustomExtension} className="button">Add</Button>
            </div>
            <div className="convert-to-pdf">
                <Typography variant="h6" className="typography">Convert All Selected, Supported Filetypes to PDF?</Typography>
                <Typography variant="body2" className="typography">Supported Filetypes: doc, docx, xls, xlsx, ppt, pptx</Typography>
                <RadioGroup
                    value={convertToPdf}
                    onChange={(e) => setConvertToPdf(e.target.value)}
                    row
                    className="radio-group"
                >
                    <FormControlLabel value="yes" control={<Radio />} label="Yes" className="form-control-label" />
                    <FormControlLabel value="no" control={<Radio />} label="No" className="form-control-label" />
                </RadioGroup>
            </div>
            <Button variant="contained" onClick={handleSave} className="button">Save</Button>
        </div>
    );
};

export default FileExtensionPicker;
