import React, { useState, useEffect } from 'react';
import {
  Button, List, ListItem, ListItemText, ListItemIcon, Typography,
  Collapse, CircularProgress, IconButton, TextField, InputAdornment, Box, Modal, Paper, Snackbar, Alert
} from '@mui/material';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import FolderIcon from '@mui/icons-material/Folder';
import AddBoxIcon from '@mui/icons-material/AddBox';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import ClearIcon from '@mui/icons-material/Clear';
import CloseIcon from '@mui/icons-material/Close';
import { useAuth } from './AuthContext';
import { Client } from '@microsoft/microsoft-graph-client';
import { db } from './firebase';
import { doc, updateDoc, arrayUnion, getDoc } from 'firebase/firestore';
import './App.css'; // Import the CSS file

const NewFolderPicker = ({ accessToken, onCompleted }) => {
  const [selectingExtractionFolder, setSelectingExtractionFolder] = useState(true);
  const [selectedExtractionFolder, setSelectedExtractionFolder] = useState(null);
  const [selectedDestinationFolder, setSelectedDestinationFolder] = useState(null);
  const [folders, setFolders] = useState([]);
  const [sharedMailboxes, setSharedMailboxes] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [loadingFolders, setLoadingFolders] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [searchDialogOpen, setSearchDialogOpen] = useState(false);
  const [mailboxName, setMailboxName] = useState('');
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const { officeUser, officeLogin } = useAuth();

  useEffect(() => {
    if (officeUser) {
      fetchFolders();
      setMailboxName(officeUser.username);
    } else {
      officeLogin(); // Ensure user is logged in with Office account
    }
  }, [officeUser]);

  const fetchFolders = async () => {
    setLoadingFolders(true);
    try {
      const client = await getClient();
      const userId = officeUser.username;
      const response = await client.api(`/users/${userId}/mailFolders?$top=100&$expand=childFolders($top=100)`).get();
      setFolders(response.value);
    } catch (error) {
      console.error('Failed to fetch folders', error);
    } finally {
      setLoadingFolders(false);
    }
  };

  const getClient = async () => {
    return Client.init({
      authProvider: (done) => done(null, accessToken),
    });
  };

  const handleSelectFolder = (folder, setSelectedFolder, mailbox) => {
    const fullPath = mailbox.mail
      ? `${mailbox.mail}\\${folder.displayName}`
      : `${officeUser.username}\\${folder.displayName}`;

    setSelectedFolder({ ...folder, fullPath, mailboxName: mailbox.mail || officeUser.username });
  };

  const handleNext = async () => {
    if (!selectedExtractionFolder) {
      console.error('No extraction folder selected');
      return;
    }

    const settingsRef = doc(db, 'settings', officeUser.username);
    const docSnap = await getDoc(settingsRef);

    if (docSnap.exists()) {
      const folderPairs = docSnap.data().folderPairs || [];
      const folderExists = folderPairs.some(pair => pair.extractionFolder.fullPath === selectedExtractionFolder.fullPath);

      if (folderExists) {
        setAlertMessage('This extraction folder already exists. Please select a different folder.');
        setAlertOpen(true);
        return;
      }
    }

    setSelectingExtractionFolder(false);
  };

  const handleConfirmSelection = async () => {
    if (!selectedExtractionFolder || !selectedDestinationFolder) {
      console.error('Both extraction and destination folders must be selected');
      return;
    }

    const newPair = {
      extractionFolder: selectedExtractionFolder,
      destinationFolder: selectedDestinationFolder,
    };

    const settingsRef = doc(db, 'settings', officeUser.username);

    try {
      await updateDoc(settingsRef, {
        folderPairs: arrayUnion(newPair),
      });

      setSelectedExtractionFolder(null);
      setSelectedDestinationFolder(null);
      setSelectingExtractionFolder(true);
      onCompleted(selectedExtractionFolder); // Pass the selected extraction folder to the parent component
    } catch (error) {
      console.error('Failed to save folder pair', error);
    }
  };

  const handleSearch = async () => {
    setLoadingSearch(true);
    try {
      const client = await getClient();
      const response = await client.api(`/users?$filter=startswith(displayName,'${searchTerm}') or startswith(mail,'${searchTerm}')`).get();
      setSearchResults(response.value);
    } catch (error) {
      console.error('Failed to search users', error);
    } finally {
      setLoadingSearch(false);
    }
  };

  const handleClearSearch = () => {
    setSearchTerm('');
    setSearchResults([]);
  };

  const handleSelectMailbox = async (mailbox) => {
    try {
      const client = await getClient();
      const response = await client.api(`/users/${mailbox.id}/mailFolders?$top=100&$expand=childFolders($top=100)`).get();
      setFolders(response.value);
      setMailboxName(mailbox.mail);
      setSearchDialogOpen(false);
      setSearchResults([]);
    } catch (error) {
      console.error('Failed to fetch mailbox folders', error);
    }
  };

  const RecursiveFolderItem = ({ folder, setSelectedFolder, mailbox, level = 0 }) => {
    const [open, setOpen] = useState(false);
    const toggleOpen = () => setOpen(!open);

    return (
      <>
        <ListItem
          button
          onClick={() => handleSelectFolder(folder, setSelectedFolder, mailbox)}
          sx={{
            pl: `${2 * level}rem`,
            bgcolor: folder === selectedExtractionFolder || folder === selectedDestinationFolder ? 'rgba(0, 0, 255, 0.1)' : 'transparent'
          }}
          className="list-item"
        >
          <ListItemIcon className="list-item-icon">
            {open ? <FolderOpenIcon /> : <FolderIcon />}
          </ListItemIcon>
          <ListItemText primary={folder.displayName} className="list-item-text" />
          {folder.childFolders && folder.childFolders.length > 0 && (
            <ListItemIcon onClick={(e) => {
              e.stopPropagation();
              toggleOpen();
            }} className="list-item-icon">
              {open ? <IndeterminateCheckBoxIcon /> : <AddBoxIcon />}
            </ListItemIcon>
          )}
        </ListItem>
        {folder.childFolders && folder.childFolders.length > 0 && (
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {folder.childFolders.map((child) => (
                <RecursiveFolderItem key={child.id} folder={child} setSelectedFolder={setSelectedFolder} mailbox={mailbox} level={level + 1} />
              ))}
            </List>
          </Collapse>
        )}
      </>
    );
  };

  const MailboxItem = ({ mailbox, setSelectedFolder }) => {
    const [open, setOpen] = useState(false);
    const toggleOpen = () => setOpen(!open);

    return (
      <>
        <ListItem button onClick={toggleOpen} className="list-item">
          <ListItemIcon className="list-item-icon">
            {open ? <FolderOpenIcon /> : <FolderIcon />}
          </ListItemIcon>
          <ListItemText primary={mailbox.displayName || mailbox.mail} className="list-item-text" />
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {mailbox.folders && mailbox.folders.map((folder) => (
              <RecursiveFolderItem key={folder.id} folder={folder} setSelectedFolder={setSelectedFolder} mailbox={mailbox} />
            ))}
          </List>
        </Collapse>
      </>
    );
  };

  return (
    <div>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2, p: 2 }} className="box">
        <Typography variant="h6" className="typography">{selectingExtractionFolder ? 'Select Source Folder' : 'Select Destination Folder'}</Typography>
        <Button variant="contained" onClick={() => setSearchDialogOpen(true)} sx={{ mb: 2 }} disabled={!selectingExtractionFolder} className="button">
          Open Different Mailbox
        </Button>
      </Box>
      {loadingFolders ? <CircularProgress className="circular-progress" /> : (
        <>
          <Box sx={{ border: '1px solid #ccc', borderRadius: '4px', p: 2, mb: 2 }} className="box">
            <Typography variant="h6" className="typography">{mailboxName}</Typography>
            <List className="list">
              {folders.map(folder => (
                <RecursiveFolderItem
                  key={folder.id}
                  folder={folder}
                  setSelectedFolder={selectingExtractionFolder ? setSelectedExtractionFolder : setSelectedDestinationFolder}
                  mailbox={{ mail: mailboxName }}
                />
              ))}
            </List>
          </Box>
        </>
      )}

      {selectedExtractionFolder && (
        <Typography variant="subtitle1" className="typography">
          Source Folder: {selectedExtractionFolder.fullPath}
        </Typography>
      )}
      {selectedDestinationFolder && (
        <Typography variant="subtitle1" className="typography">
          Destination Folder: {selectedDestinationFolder.fullPath}
        </Typography>
      )}

      <Modal
        open={searchDialogOpen}
        onClose={() => setSearchDialogOpen(false)}
        aria-labelledby="search-shared-mailbox-modal-title"
        aria-describedby="search-shared-mailbox-modal-description"
        className="modal"
      >
        <Paper sx={{ position: 'relative', p: 2, width: '60%' }} className="paper">
          <IconButton
            aria-label="close"
            onClick={() => setSearchDialogOpen(false)}
            className="modal-close-button"
          >
            <CloseIcon />
          </IconButton>
          <Typography id="search-shared-mailbox-modal-title" variant="h6" component="h2" className="typography">
            Search Shared Mailboxes
          </Typography>
          <Box sx={{ mt: 2 }} className="box">
            <TextField
              label="Search Mailbox"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleClearSearch} className="icon-button">
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                )
              }}
              className="text-field"
            />
            <Button variant="contained" onClick={handleSearch} sx={{ mt: 2 }} disabled={loadingSearch} className="button">
              {loadingSearch ? <CircularProgress size={24} className="circular-progress" /> : 'Search'}
            </Button>
            {searchResults.length > 0 && (
              <Box sx={{ mt: 2 }} className="box">
                <Typography variant="subtitle1" className="typography">Select a mailbox to open:</Typography>
                <List className="list">
                  {searchResults.map(mailbox => (
                    <ListItem button onClick={() => handleSelectMailbox(mailbox)} key={mailbox.id} className="list-item">
                      <ListItemText primary={`${mailbox.displayName} (${mailbox.mail})`} className="list-item-text" />
                    </ListItem>
                  ))}
                </List>
              </Box>
            )}
          </Box>
        </Paper>
      </Modal>

      {selectingExtractionFolder ? (
        <Button variant="contained" onClick={handleNext} disabled={!selectedExtractionFolder} className="button">Next</Button>
      ) : (
        <Button variant="contained" onClick={handleConfirmSelection} disabled={!selectedDestinationFolder} className="button">Confirm</Button>
      )}

      <Snackbar open={alertOpen} autoHideDuration={6000} onClose={() => setAlertOpen(false)} className="snackbar">
        <Alert onClose={() => setAlertOpen(false)} severity="warning" sx={{ width: '100%' }} className="alert">
          {alertMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default NewFolderPicker;
