import React, { useState, useEffect } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, CircularProgress, TextField, Box, Tooltip, IconButton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SearchIcon from '@mui/icons-material/Search';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { db } from './firebase';
import { collection, getDocs } from 'firebase/firestore';

const DownloadLogs = () => {
    const [logs, setLogs] = useState([]);
    const [filteredLogs, setFilteredLogs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
        const fetchLogs = async () => {
            setLoading(true);
            const logsCollection = collection(db, 'downloadLogs');
            const logsSnapshot = await getDocs(logsCollection);
            const logsList = await Promise.all(
                logsSnapshot.docs.map(async (doc) => {
                    const logId = doc.id;
                    const logsSubCollection = collection(db, 'downloadLogs', logId, 'logs');
                    const logsSubSnapshot = await getDocs(logsSubCollection);
                    const nestedLogs = logsSubSnapshot.docs.map((subDoc) => ({ id: subDoc.id, ...subDoc.data() }));
                    return { id: logId, ...doc.data(), nestedLogs };
                })
            );

            logsList.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp)); // Sort by timestamp descending
            setLogs(logsList);
            setFilteredLogs(logsList);
            setLoading(false);
        };

        fetchLogs();
    }, []);

    useEffect(() => {
        // Filter logs based on search query
        const lowerCaseQuery = searchQuery.toLowerCase();
        const filtered = logs.filter((log) =>
            log.link.toLowerCase().includes(lowerCaseQuery) ||
            log.username?.toLowerCase().includes(lowerCaseQuery) ||
            log.messageId?.toLowerCase().includes(lowerCaseQuery) ||
            log.nestedLogs.some((nestedLog) =>
                nestedLog.action.toLowerCase().includes(lowerCaseQuery) ||
                nestedLog.downloadUrl?.toLowerCase().includes(lowerCaseQuery) ||
                nestedLog.status?.toLowerCase().includes(lowerCaseQuery)
            )
        );
        setFilteredLogs(filtered);
    }, [searchQuery, logs]);

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text);
    };

    if (loading) {
        return <CircularProgress />;
    }

    return (
        <Box>
            <Box display="flex" alignItems="center" mb={2}>
                <SearchIcon />
                <TextField
                    label="Search logs"
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    sx={{ ml: 1 }}
                />
            </Box>
            <Box>
                {filteredLogs.map((log) => (
                    <Accordion key={log.id} sx={{ mb: 1 }}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}
                            sx={{ backgroundColor: '#f5f5f5', fontWeight: 'bold', overflow: 'hidden', textAlign: 'left' }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                <Typography
                                    variant="subtitle1"
                                    sx={{
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        wordBreak: 'break-all',
                                        textAlign: 'left',
                                        flex: 1
                                    }}
                                >
                                    {new Date(log.timestamp).toLocaleString()} - {log.link}
                                </Typography>
                                <Tooltip title="Copy link" sx={{ ml: 1 }}>
                                    <IconButton onClick={() => copyToClipboard(log.link)} size="small">
                                        <ContentCopyIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Box mb={2}>
                                <Typography><strong>User ID:</strong> {log.username}</Typography>
                                <Typography><strong>Message ID:</strong> {log.messageId}</Typography>
                                <Typography sx={{ wordBreak: 'break-all' }}><strong>Link:</strong> {log.link}</Typography>
                                <Tooltip title="Copy link">
                                    <IconButton onClick={() => copyToClipboard(log.link)} size="small">
                                        <ContentCopyIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                                <Typography><strong>Timestamp:</strong> {new Date(log.timestamp).toString()}</Typography>
                            </Box>
                            {log.nestedLogs.map((nestedLog) => (
                                <Accordion key={nestedLog.id} sx={{ mb: 1 }}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}
                                        sx={{ backgroundColor: '#e0f7fa', fontWeight: 'bold', overflow: 'hidden', textAlign: 'left' }}>
                                        <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                            <Typography
                                                variant="body1"
                                                sx={{
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    wordBreak: 'break-all',
                                                    textAlign: 'left',
                                                    flex: 1
                                                }}
                                            >
                                                {new Date(nestedLog.timestamp).toLocaleString()} - {nestedLog.action}
                                            </Typography>
                                            {nestedLog.downloadUrl && (
                                                <Tooltip title="Copy download URL" sx={{ ml: 1 }}>
                                                    <IconButton onClick={() => copyToClipboard(nestedLog.downloadUrl)} size="small">
                                                        <ContentCopyIcon fontSize="small" />
                                                    </IconButton>
                                                </Tooltip>
                                            )}
                                        </Box>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography><strong>Action:</strong> {nestedLog.action}</Typography>
                                        <Typography><strong>Timestamp:</strong> {new Date(nestedLog.timestamp).toString()}</Typography>
                                        {nestedLog.downloadUrl && <Typography sx={{ wordBreak: 'break-all' }}><strong>Download URL:</strong> {nestedLog.downloadUrl}</Typography>}
                                        {nestedLog.uploadPath && <Typography><strong>Upload Path:</strong> {nestedLog.uploadPath}</Typography>}
                                        {nestedLog.status && <Typography><strong>Status:</strong> {nestedLog.status}</Typography>}
                                        {nestedLog.error && <Typography><strong>Error:</strong> {nestedLog.error}</Typography>}
                                    </AccordionDetails>
                                </Accordion>
                            ))}
                        </AccordionDetails>
                    </Accordion>
                ))}
            </Box>
        </Box>
    );
};

export default DownloadLogs;
