import React, { useState, useEffect } from 'react';
import { Stepper, Step, StepLabel, Button, Typography, Box, Card, CardContent, List, ListItem, ListItemText, Divider, StepIconProps } from '@mui/material';
import NewFolderPicker from './NewFolderPicker';
import SharePointFolderSelector from './SharePointFolderSelector';
import FileExtensionPicker from './FileExtensionPicker';
import './App.css'; // Import the CSS file

function getSteps(existingValues) {
    if (existingValues) {
        // Skip the first step if editing an existing rule
        return ['Select SharePoint Folders', 'Select File Extensions', 'Summary'];
    }
    return ['Select Mailbox Folders', 'Select SharePoint Folders', 'Select File Extensions', 'Summary'];
}

function getStepContent(stepIndex, handleNext, handleBack, accessToken, selectedExtractionFolder, setSelectedExtractionFolder, folderPairs, setFolderPairs, existingValues, userId) {
    console.log("Stepper:", userId)
    if (existingValues && stepIndex === 0) {
        // If editing, directly move to the second step
        return <SharePointFolderSelector accessToken={accessToken} selectedExtractionFolder={selectedExtractionFolder} onCompleted={handleNext} userId={userId} />;
    }

    switch (stepIndex) {
        case 0:
            return <NewFolderPicker accessToken={accessToken} onCompleted={(folder) => { setSelectedExtractionFolder(folder); handleNext(); }}/>;
        case 1:
            return <SharePointFolderSelector accessToken={accessToken} selectedExtractionFolder={selectedExtractionFolder} onCompleted={handleNext} userId={userId} />;
        case 2:
            return <FileExtensionPicker extractionFolder={selectedExtractionFolder} onSave={(updatedFolderPairs) => { setFolderPairs(updatedFolderPairs); handleNext(); }} userId={userId}/>;
        case 3:
            return <Summary folderPairs={folderPairs} selectedExtractionFolder={selectedExtractionFolder} userId={userId} />;
        default:
            return 'Unknown step';
    }
}


function Summary({ folderPairs, selectedExtractionFolder, userId }) {
    const filteredPairs = folderPairs.filter(pair => pair.extractionFolder.id === selectedExtractionFolder.id);

    return (
        <Box sx={{ mt: 3 }}>
            <Typography variant="h5" align="center" gutterBottom className="summary-title">
                Summary of Extraction Rule
            </Typography>
            {filteredPairs.map((pair, index) => (
                <Card key={index} className="card">
                    <CardContent>
                        <List>
                            <ListItem>
                                <ListItemText 
                                    primary="Source Mail Folder" 
                                    secondary={pair.extractionFolder.fullPath} 
                                    className="list-item-text"
                                />
                            </ListItem>
                            <Divider />
                            <ListItem>
                                <ListItemText 
                                    primary="Destination Mail Folder" 
                                    secondary={pair.destinationFolder.fullPath} 
                                    className="list-item-text"
                                />
                            </ListItem>
                            <Divider />
                            <ListItem>
                                <ListItemText 
                                    primary="Attachment Save Folder" 
                                    secondary={pair.extractionFolder.selectedSharePointFolder.path} 
                                    className="list-item-text"
                                />
                            </ListItem>
                            <Divider />
                            <ListItem>
                                <ListItemText 
                                    primary="Attachment Extensions to Save" 
                                    secondary={pair.extractionFolder.extensions.join(', ')} 
                                    className="list-item-text"
                                />
                            </ListItem>
                            <Divider />
                            <ListItem>
                                <ListItemText 
                                    primary="Convert all attachments to PDF" 
                                    secondary={pair.extractionFolder.convertToPdf === "yes" ? "Yes" : "No"} 
                                    className="list-item-text"
                                />
                            </ListItem>
                        </List>
                    </CardContent>
                </Card>
            ))}
        </Box>
    );
}

const CustomStepIcon = (props: StepIconProps) => {
    const { active, completed, className } = props;

    const iconClasses = {
        root: 'step-icon',
        active: 'step-icon-active',
        completed: 'step-icon-completed',
    };

    const stepClass = active
        ? iconClasses.active
        : completed
        ? iconClasses.completed
        : iconClasses.root;

    return (
        <div className={`${stepClass} ${className}`}>
            <span className="step-number">{props.icon}</span>
        </div>
    );
};

export default function ExtractionSetupStepper({ accessToken, onClose, existingValues, userId }) {
    const [activeStep, setActiveStep] = useState(existingValues ? 1 : 0);
    const [selectedExtractionFolder, setSelectedExtractionFolder] = useState(existingValues?.extractionFolder || null);
    const [folderPairs, setFolderPairs] = useState(existingValues ? [existingValues] : []);
    const steps = getSteps(existingValues);

    const handleNext = () => {
        if (activeStep === steps.length - 1) {
            onClose(); // Close the dialog on the last step and refresh the home component
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(existingValues ? 1 : 0);
        setSelectedExtractionFolder(existingValues?.extractionFolder || null);
        setFolderPairs(existingValues ? [existingValues] : []);
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Stepper activeStep={activeStep} alternativeLabel className="stepper">
                {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel className="step-label" StepIconComponent={CustomStepIcon}>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            <div>
                {activeStep === steps.length ? (
                    <div className="step-content">
                        <Typography sx={{ mt: 2, mb: 1 }}>
                            All steps completed - you&apos;re finished
                        </Typography>
                        <Button onClick={handleReset} className="button">Reset</Button>
                    </div>
                ) : (
                    <div className="step-content">
                        <Typography sx={{ mt: 2, mb: 1 }}>
                            Step {activeStep + 1}
                        </Typography>
                        <Box>{getStepContent(activeStep, handleNext, handleBack, accessToken, selectedExtractionFolder, setSelectedExtractionFolder, folderPairs, setFolderPairs, existingValues, userId)}</Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                            <Button
                                color="inherit"
                                disabled={activeStep === (existingValues ? 1 : 0)}
                                onClick={handleBack}
                                sx={{ mr: 1 }}
                                className="button"
                            >
                                Back
                            </Button>
                            <Box sx={{ flex: '1 1 auto' }} />
                            <Button onClick={handleNext} disabled={activeStep === 1 && !selectedExtractionFolder} className="button">
                                {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                            </Button>
                        </Box>
                    </div>
                )}
            </div>
        </Box>
    );
}
