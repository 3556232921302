import React, { useState } from 'react';
import { Box, Button, TextField, Typography, FormControl, RadioGroup, FormControlLabel, Radio, Checkbox } from '@mui/material';
import CompareUrls from './CompareUrls';
import URLConstructor from './URLConstructor';

const AddNewDownloadRule = ({
    newRule,
    setNewRule,
    generatedPattern,
    setGeneratedPattern,
    downloadUrlTemplate,
    setDownloadUrlTemplate,
    regexPattern,
    setRegexPattern,
    matchingRule,
    setMatchingRule,
    onAddRule, // This will save the final rule
}) => {
    const [addToPredefined, setAddToPredefined] = useState(false);
    const [showComparison, setShowComparison] = useState(false);
    const [showURLConstructor, setShowURLConstructor] = useState(false); // Toggle to show URL constructor
    const [finalConstructedURL, setFinalConstructedURL] = useState(''); // Store final constructed URL
    const [showAddRuleButton, setShowAddRuleButton] = useState(false); // Toggle to show Add Rule button

    const handleInputChange = (index, value) => {
        const updatedUrls = [...newRule.urls];
        updatedUrls[index] = value;
        setNewRule(prev => ({ ...prev, urls: updatedUrls }));
    };

    const handleMatchingRuleChange = (event) => {
        setMatchingRule(event.target.value);
        setShowComparison(false); // Reset the comparison when matching rule changes
    };

    const processRule = () => {
        const { urls } = newRule;

        // Use URL Example 1 as the downloadUrl
        const downloadUrl = urls[0];

        if (urls.every(url => url)) {
            setNewRule(prev => ({ ...prev, downloadUrl })); // Set downloadUrl as URL Example 1
            setShowComparison(true);  // Show comparison to trigger `CompareUrls`
            setShowURLConstructor(false); // Hide the URL constructor at first
        } else {
            setGeneratedPattern('');
            setDownloadUrlTemplate('');
            setRegexPattern('');
        }
    };

    const handleGenerateRegex = (generatedRegex) => {
        setRegexPattern(generatedRegex);

        // Use the first URL example as the download URL
        const downloadUrl = newRule.urls[0];

        // Generate the download URL template based on the generated regex
        const downloadTemplate = generateDownloadUrlTemplate(generatedRegex, downloadUrl);
        setDownloadUrlTemplate(downloadTemplate);
        setShowURLConstructor(true); // Show URL constructor after generating template
    };

    const generateDownloadUrlTemplate = (regexPattern, downloadUrl) => {
        const flexibleRegexPattern = regexPattern.replace(/^\^|\$$/g, ''); // Remove ^ and $ to allow flexible matching
        const regex = new RegExp(flexibleRegexPattern); // Convert regex string to actual regex
        const match = regex.exec(downloadUrl); // Match the download URL using the generated regex

        if (!match) {
            console.error("The download URL does not match the generated regex pattern.");
            return downloadUrl; // Return the original URL if there's no match
        }

        let downloadTemplate = downloadUrl;
        let variableCount = 1;

        // Replace each matching dynamic part in the download URL with {variableN}
        for (let i = 1; i < match.length; i++) {
            const capturedPart = match[i]; // The dynamic part that was captured by the regex
            downloadTemplate = downloadTemplate.replace(capturedPart, `{variable${variableCount++}}`);
        }

        return downloadTemplate;
    };

    // When URL is constructed, show the Add Rule button
    const handleConstructedURL = (constructedURL) => {
        setFinalConstructedURL(constructedURL);
        setShowAddRuleButton(true); // Show the Add Rule button
    };

    return (
        <Box mt={2} className="add-rule">
            {/* Other form fields */}
            <TextField
                label="Sender Domain"
                value={newRule.senderDomain}
                onChange={(e) => setNewRule({ ...newRule, senderDomain: e.target.value })}
                fullWidth
                className="text-field"
                required
            />

            {newRule.urls.map((url, index) => (
                <TextField
                    key={index}
                    label={`URL Example ${index + 1}`}
                    value={url}
                    onChange={(e) => handleInputChange(index, e.target.value)}
                    fullWidth
                    className="text-field"
                    required
                />
            ))}

            <FormControl component="fieldset">
                <Typography variant="subtitle1">Matching Rule</Typography>
                <RadioGroup
                    aria-label="matching-rule"
                    value={matchingRule}
                    onChange={handleMatchingRuleChange}
                >
                    <FormControlLabel value="whole" control={<Radio />} label="Match exact URL only from start to finish" />
                    <FormControlLabel value="start" control={<Radio />} label="Match start of URL exactly, flexible finish" />
                    <FormControlLabel value="end" control={<Radio />} label="Match end of URL exactly, flexible start" />
                    <FormControlLabel value="any" control={<Radio />} label="Match whole URL with flexible start and finish" />
                </RadioGroup>
            </FormControl>

            <FormControlLabel
                control={
                    <Checkbox
                        checked={addToPredefined}
                        onChange={(e) => setAddToPredefined(e.target.checked)}
                        color="primary"
                    />
                }
                label="Save this rule to predefined rules"
            />

            <Button
                onClick={processRule}
                variant="contained"
                color="primary"
                className="button"
            >
                Check Rule
            </Button>

            {generatedPattern && (
                <>
                    <Typography variant="body2" className="typography">
                        Generated Pattern: {generatedPattern}
                    </Typography>
                    <Typography variant="body2" className="typography">
                        Download URL Template: {downloadUrlTemplate}
                    </Typography>
                    <Typography variant="body2" className="typography">
                        Regex Pattern: {regexPattern}
                    </Typography>
                </>
            )}

            {showComparison && (
                <CompareUrls 
                    urls={newRule.urls} 
                    onGenerateRegex={handleGenerateRegex} 
                    matchingRule={matchingRule}  // Pass matchingRule as a prop
                />
            )}

            {showURLConstructor && (
                <URLConstructor 
                    downloadUrlTemplate={downloadUrlTemplate} 
                    onConstructURL={handleConstructedURL} // Pass back the final constructed URL
                />
            )}

            {showAddRuleButton && (
                <Button
                    onClick={() => onAddRule(newRule, finalConstructedURL, addToPredefined)} // Save the finalConstructedURL
                    variant="contained"
                    color="primary"
                    className="button"
                >
                    Add Rule
                </Button>
            )}

            {finalConstructedURL && (
                <Typography variant="body2" className="typography">
                    Download URL: {finalConstructedURL}
                </Typography>
            )}
        </Box>
    );
};

export default AddNewDownloadRule;
